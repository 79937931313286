import React, { useEffect } from "react";
import { emailValidation, minLength} from 'lib/outilsForms'
// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Select from "react-select";
import StatusUser from "lib/Constant/Status/User.constant";
import SocieteServices from "services/Api/Controllers/SocieteServices";
import AuthService from "services/Api/Controllers/AuthService";
import TypeOption from "lib/Constant/interface/forms"
import { Redirect } from "react-router-dom";
import { CustomInput } from "reactstrap";

export default function ValidationForms() {


    const [typeSocietemultipleSelectState, setTypeSocietemultipleSelectState] = React.useState(true);  
    const [typenomState, setTypenomState] = React.useState(true);
    const [typeprenomState, setTypeprenomState] = React.useState(true);
    const [typeEmailState, setTypeEmailState] = React.useState(true);  
    const [typePasswordState, setTypePasswordState] = React.useState(true);  
  
    const [typeDeCompte, settypeDeCompte] = React.useState<TypeOption|undefined>(undefined);
    const [typeSocietemultipleSelect, settypeSocietemultipleSelect] = React.useState<TypeOption[]>([]);
    const [typenom, setTypenom] = React.useState("");
    const [typeprenom, setTypeprenom] = React.useState("");
    const [typeEmail, setTypeEmail] = React.useState("");
    const [typePassword, setTypepassword] = React.useState("");
    const [typecatalogue, settypecatalogue] = React.useState(false);
  
    const [AllSociete, setallSociete] = React.useState([]);  
    const [succesForm,setSuccesForm]=React.useState('')
  
    const onSubmitRegister= () =>{
      var list_societe=[]
      var val_type:number=+typeDeCompte.value;
      typeSocietemultipleSelect.map((item)=>(list_societe.push(item.value)));
  
      async function data() {
      const boolres=await AuthService.signUp(typeEmail,typePassword,typenom,typeprenom,list_societe,val_type,typecatalogue);
        setSuccesForm(boolres);
      }
      data();
    }
    
  
  
    useEffect(() => {
      let isSubscribed = true;
    
      async function loadData() {
        if(isSubscribed) {
          const societes = await SocieteServices.getALLSocietes();
          setallSociete(societes);
        }
      }
        if(isSubscribed) {
            loadData();
        }
      
        return () => {
          isSubscribed = false;
        }
    },[succesForm]);
  
    
    if(succesForm!==''&&succesForm!=='false'){
      var val_type:number=parseInt(typeDeCompte.value);
      if(val_type>1) return <Redirect to="/auth/register-step2"/>
      if(val_type<=1) return <Redirect to="/admin/dashboard"/>
    }

    
  const authoriseToSubmit=()=>{
    const  ispasswordValid = typePassword !== '';
    const  isEmailValid = typeEmail !== ''&&emailValidation(typeEmail)
    const  isNomValide = typenom !== ''
    const  isPrenomValide = typeprenom !== ''
    const isSocieteSelected= typeSocietemultipleSelect&&typeSocietemultipleSelect.length>0;

    setTypePasswordState(ispasswordValid)
    setTypeEmailState(isEmailValid)
    setTypenomState(isNomValide);
    setTypeprenomState(isPrenomValide);
    if(!isSocieteSelected) {setTypeSocietemultipleSelectState(false)}

    if(
      isSocieteSelected
        &&(isPrenomValide
        &&isEmailValid
        &&isNomValide
        &&isPrenomValide
        &&(typeDeCompte!==null))
      ){
        return true;
      }
      else{
        return false;
      }


  }

    return (
      <Container fluid>
        <Row className="justify-content-md-center ml-auto mr-auto">
          <Col  lg="10">
            <Form
              action=""
              className="form-horizontal"
              id="TypeValidation"
              method=""
            >
              <Card>
                <Card.Body>
                  {/* type de compte */}
                    <Row>
                    <Form.Label column sm="2">
                      Type de compte
                      </Form.Label>
                      <Col md="7">
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="typeDeCompte"
                          value={typeDeCompte}
                          onChange={(value) => settypeDeCompte(value)}
                          options={StatusUser}
                          placeholder="Selectionner le type de compte"
                        />
                          {typeDeCompte ? null : (
                          <label className="error" style={{color:"red"}}>
                            Ce champ doit être selectionner.
                          </label>
                        )}
                      </Col>
                    </Row>
                  {/* Societe Associe */}
                  <Row>
                    <Form.Label column sm="2">
                          Societé(s) Associé(s) 
                    </Form.Label>
                    <Col md="7">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Choisir les sociétés"
                          name="multipleSelect"
                          closeMenuOnSelect={false}
                          isMulti
                          value={typeSocietemultipleSelect}
                          onChange={(value) =>{setTypeSocietemultipleSelectState(true);settypeSocietemultipleSelect(value);}}
                          options={AllSociete}
                        />
                           {typeSocietemultipleSelectState ? null : (
                          <label className="error" style={{color:"red"}}>
                            Ce champ doit être selectionner.
                          </label>
                        )}
                      </Col>
                  </Row>
                  {/* Nom */}
                  <Row>
                  <Form.Label column sm="2">
                    Nom
                    </Form.Label>
                  <Col sm="7">
                    <Form.Group className={typenomState ? "has-success" : "has-error"}>
                      <Form.Control
                        name="nom"
                        type="text"
                        value={typenom}
                        onChange={(e) => {
                          setTypenom(e.target.value);
                          if (minLength(e.target.value, 2)) {
                            setTypenomState(true);
                          } else {
                            setTypenomState(false);
                          }
                        }}
                      ></Form.Control>
                         {typenomState ? null : (
                          <label className="error" style={{color:"red"}}>
                            Ce champ doit être selectionner.
                          </label>
                            )}
                    </Form.Group>
                  </Col>
                </Row>
                  {/* Prenom */}
                  <Row>
                    <Form.Label column sm="2">
                      Prenom
                      </Form.Label>
                    <Col sm="7">
                      <Form.Group className={typeprenomState ? "has-success" : "has-error"}>
  
                        <Form.Control
                          name="prenom"
                          type="text"
                          value={typeprenom}
                          onChange={(e) => {
                            setTypeprenom(e.target.value);
                            if (minLength(e.target.value, 2)) {
                              setTypeprenomState(true);
                            } else {
                              setTypeprenomState(false);
                            }
                          }}
                        ></Form.Control>
                            {typeprenomState ? null : (
                          <label className="error" style={{color:"red"}}>
                            Ce champ doit être selectionner.
                          </label>
                            )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Email */}
                  <Row>
                    <Form.Label column sm="2">
                      Mail
                      </Form.Label>
                    <Col sm="7">
  
                      <Form.Group className={typeEmailState ? "has-success" : "has-error"}>
  
                        <Form.Control
                          name="email"
                          type="text"
                          value={typeEmail}
                          onChange={(e) => {
                            setTypeEmail(e.target.value);
                            if (emailValidation(e.target.value)) {
                              setTypeEmailState(true);
                            } else {
                              setTypeEmailState(false);
                            }
                          }}
                        ></Form.Control>
                        {typeEmailState ? null : (
                          <label className="error">
                            Ce champ doit être une adresse électronique.
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Mot de passe */}
                  <Row>
                    <Form.Label column sm="2">Accès au catalogue</Form.Label>
                    <Col sm="7">
                      <Form.Group className={typePasswordState ? "has-success" : "has-error"}>
                        <Form.Control
                          name="password"
                          type="password"
                          value={typePassword}
                          onChange={(e) => {
                            setTypepassword(e.target.value);
                            if (minLength(e.target.value,6)) {
                              setTypePasswordState(true);
                            } else {
                              setTypePasswordState(false);
                            }
                          }}
                        ></Form.Control>
                        {typePasswordState ? null : (
                          <label className="error">
                            Le mot de passe doit avoir un minimum de 6 caracteres.
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Acces au catalogue */}
                  <Row>
                    <Form.Label column sm="2">Accès au catalogue</Form.Label>
                    <Col sm="7">
                      <CustomInput
                          defaultChecked={typecatalogue}
                          type="switch"
                          id="customSwitch"
                          onClick={() => settypecatalogue(!typecatalogue)}
                        />
                    </Col>
                  </Row>
                  
  
                </Card.Body>
  
                <Card.Footer
                 className="text-center">
                  <Button
                    variant="info"
                    onClick={(e) => {
  
                      e.preventDefault();
                      
                      (authoriseToSubmit())?onSubmitRegister():alert("Une erreur c'est produite");
                    }}
                  >
                    Creer le compte
                    </Button>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
  
import ApiService from '../ApiService';
import AuthService from './AuthService';
import SocieteServices from './SocieteServices';


class BDCService {
  

  static saveBDCtoDB(data) {

    return ApiService.post("bdc/saveBDC",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200){ return res.id;}
        if (res.status===500){return "existdeja";}
        else{return "error";} 
    })
    .catch(err=>{
        throw err
    })
  }

  static getAllbdc(){
    return ApiService.post("bdc/getAll",{societeID:SocieteServices.getSociete()},AuthService.getToken())
    .then(res=>{return res;})
    .catch(error=>{
      throw error;
    })
  }

 

  static updateData(data) {
    return ApiService.put("product/update",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200){return data.idProduct;}
        else{return "error";} 
       })
    .catch(err=>
        {
          console.log(err)
            throw err
        })
  }
  
  static deletebdc(id_delete){
    return ApiService.post("bdc/delete",{id:id_delete},AuthService.getToken())
    .then(res=>{
      if (res.status===200)return true 
      else return false
    })
    .catch(error=>{
      throw error;
    })
  }
  static getByIDbdcGuest(id){
    return ApiService.post("bdc/getByIDGuest",{id:id})
    .then(res=>{
      return res;
    })
    .catch(error=>{
      throw error;
    })
  }
  static Guestupdateisaccepted(id,isaccepted) {
    return ApiService.put("bdc/updateisaccepted",{id:id,isaccepted:isaccepted})
    .then(res=>{
        if (res.status===200){return "ok"}
        else{return "error";} 
       })
    .catch(err=>
        {
          console.log(err)
            throw err
        })
  }
  
  static getByID(id){
    return ApiService.post("bdc/getByID",{id:id},AuthService.getToken())
    .then(res=>{
      return res;
    })
    .catch(error=>{
      throw error;
    })
  }
  
}

export default BDCService;



/*eslint-disable*/
import React from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import {Table}from "react-bootstrap";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// reactstrap components
import { Container, Row, Col, FormGroup } from "react-bootstrap";
import { CustomInput,Input } from "reactstrap";

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <FormGroup>
      <Input
        placeholder={`Recherche dans ${count}...`}
        type="text"
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Tables({ columns, data }) {
  const [numberOfRows, setNumberOfRows] = React.useState({
    value: 20,
    label: "20 Colonnes",
  });
  const [pageSelect, handlePageSelect] = React.useState({
    value: 0,
    label: "Page 1",
  });
  const [switchSearch, setSwitchSearch] = React.useState(false);
  let numberOfRowsData = [20,50, 100];
  
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Configurons notre interface utilisateur de filtre par défaut.
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 20, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );


  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () {});


  const toggleSwitchSearch = () => {
    setAllFilters([]);
    setSwitchSearch(!switchSearch);
  };

  return (
    <>
         <Row  style={{marginBottom:"20px"}}>
            <Col md={{span:1,offset:11}}>
            <label>
              &nbsp;&nbsp;&nbsp;&nbsp;Filtrer
            </label>
            <CustomInput
              defaultChecked={switchSearch}
              className="table-search-switch"
              type="switch"
              name="customSwitch"
              id="customSwitch"
              onClick={toggleSwitchSearch}
            />
            </Col>
          </Row>
   
      <div className="ReactTable  -striped  -highlight primary-pagination ">
       <Table responsive {...getTableProps()} >
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr" style={{borderRadius: "12px 12px 0 0",background:"#6c757d"}}>
                {headerGroup.headers.map((column, key) => (
                  <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classnames("rt-th rt-resizable-header", {
                    "-cursor-pointer": column.Filter!== false,
                    "-sort-asc": column.isSorted && !column.isSortedDesc,
                    "-sort-desc": column.isSorted && column.isSortedDesc,
                  })}
                  >
                    <div className="rt-resizable-header-content" style={{textAlignLast:"center", color:"white"}}>
                      <span  >
                      {column.render("Header")}
                      </span>
                    </div>
                    {/* Render the columns filter UI */}
                    {switchSearch ? (<div>
                      {column.canFilter
                        ? column.render("Filter")
                        : null}
                    </div>):null}
                  </th>
                 
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 }
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="rt-td" style={{textAlignLast:"center"}}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
    
        <div className="pagination-bottom" style={{marginTop:"30px"}} >
          <div className="-pagination">
            <div className="-previous">
              <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="-btn"
              >
                Précédent
              </button>
            </div>
            <div className="-center">
              <Container>
                <Row className="justify-content-center">
                  
                  <Col md="6" sm="6" xs="12">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="pageSelect"
                      value={pageSelect}
                      onChange={(value) => {
                        gotoPage(value.value);
                        handlePageSelect(value);
                      }}
                      options={pageSelectData.map((prop, key) => {
                        return {
                          value: key,
                          label: "Page " + (key + 1),
                        };
                      })}
                      placeholder="Choisir la page"
                    />
                  </Col>
                  <Col md="6" sm="6" xs="12">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="numberOfRows"
                      value={numberOfRows}
                      onChange={(value) => {
                        setPageSize(value.value);
                        setNumberOfRows(value);
                      }}
                      options={numberOfRowsData.map((prop) => {
                        return {
                          value: prop,
                          label: prop + " Colonnes",
                        };
                      })}
                      placeholder="Choisir les rangs"
                    />
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="-next">
              <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="-btn"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
     
      </div>
      </>
  );
}

// // Define a custom filter filter function!
// function filterGreaterThan(rows, id, filterValue) {
//   return rows.filter((row) => {
//     const rowValue = row.values[id];
//     return rowValue >= filterValue;
//   });
// }

// // C'est une méthode autoRemove sur la fonction de filtre qui
// // lorsqu'elle reçoit la nouvelle valeur du filtre et renvoie vrai, le filtre
// // sera automatiquement supprimé. Normalement, c'est juste une vérification non définie
// // mais ici, nous voulons supprimer le filtre s'il ne s'agit pas d'un nombre.
// filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Tables;

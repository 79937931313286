
const PayementStatus=[
  { value: "1", label: "Mensuel"},
  { value: "3", label: "Trimestriel"},
  { value: "12", label: "Annuel"},
];


export default PayementStatus;

  
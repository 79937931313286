import React, { useEffect, useState } from "react";
import {
    Card,
    Container,
    Row,
    Col,
    Spinner,
    Alert
} from "react-bootstrap";
import OffreService from "services/Api/Controllers/OffreService";
import TableBDC from './TableBDC'
import TableBDCWait from './TableBdcWait'

export default function AllBDC() {
    const [loading, setloading] = useState(true);
    const [data,setData]=useState(null);


    useEffect(() => {
        let mounted=true
        async function loadDataPropal() {
            const propal = await OffreService.getallToBdc();
            if (mounted) {
                setData(propal);
                setloading(false);
            }
          }
          if(loading){
              loadDataPropal();
          }
        return () => {mounted=false }
    }, [])
    // eslint-disable-next-line no-unused-vars


    const PleaseSelect=()=>{
        return (
          <Row>
          <Col md={"12"}>
            <div className="text-center" style={{ padding: "20px" }}>
              <Alert variant="warning">
                <span style={{ fontSize: "large", fontWeight: "bold" }}><i className="nc-icon nc-bell-55"></i> Aucune proposition n&apos;est en atente de creation de bon de commande</span>
              </Alert>
            </div>
          </Col>
        </Row>
        )
      }
    const WaitToBdc=()=>{
        return (
          <Row>
          <Col md={"12"}>
            <div className="text-center" style={{ padding: "30px" }}>
              <Alert variant="success">
                <span style={{ fontSize: "large", fontWeight: "bold" }}><i className="nc-icon nc-bell-55"></i> {data?.length} proposition en atente de creation de bon de commande</span>
              </Alert>
            </div>
          </Col>
        </Row>
        )
      }

    return (
        <Container fluid>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                        {(data?.length!==0)?WaitToBdc():null}
                        </Card.Header>
                        <Card.Body>
                            {(data?.length===0)?PleaseSelect()
                            : <TableBDCWait/>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>

               {(loading)? <Spinner animation="border" variant="primary" />:
                <Col md="12">
                    <Card>
                        <Card.Header style={{ paddingTop: "20px", paddingLeft: "50px" }}>
                            
                        </Card.Header>
                        <Card.Body>
                            
                            <TableBDC/>
                        </Card.Body>
                    </Card>
                </Col>
                }
            </Row>
        </Container>

    )
}

const SocieteConstant=[
  { value: "6051cba7f2bbbe0cc883c407", label: "WELINK"},
  { value: "607ecefb472b00e47aa54a1f", label: "LINTEL"},
  { value: "6051cc39f2bbbe0cc883c408", label: "SIB"},
];


export default SocieteConstant;

  
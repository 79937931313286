/* eslint-disable react/display-name */
import React,{useState,useEffect} from "react";
import {
  Badge,
  Button,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable";
import {useHistory} from "react-router-dom";
import ProductService from "services/Api/Controllers/ProductService";
import SelectColumnFilter from "components/ReactTable/SelectColumnFilter";
import ViewProduct from "./ViewProduct";
export default function TableProduit() {


  const [dataTable,setDataTable]=useState([]);
  const [dataTableCategorie,setDataTableCategorie]=useState([]);
  const [dataTableMarque,setDataTableMarque]=useState([]);
  const [data,setData]=useState([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [loadingDataTable, setloadingDataTable] = useState<Boolean>(true); 
  const [loadingCategorieTable, setloadingCategorieTable] = useState<Boolean>(true); 
  const [loadingMarqueTable, setloadingMarqueTable] = useState<Boolean>(true); 
  const [width, setWidth] = React.useState(window.innerWidth);

  let history = useHistory();

  React.useEffect(() => {
    window.addEventListener("resize", () => {setWidth(window.innerWidth)});
    return () => window.removeEventListener("resize", () => {setWidth(window.innerWidth)});
  });
  
  useEffect(() => {
    let isSubscribed = true;
    async function loadDataProduit() {
        const Produit = await ProductService.getallProduct();
        if(isSubscribed)  {
          setDataTable(Produit);
          setloadingDataTable(false);
        }
    }
    async function loadDataCategorie() {
        const Produit = await ProductService.getallCategorie();
        if(isSubscribed)  {
          setDataTableCategorie(Produit);
          setloadingCategorieTable(false);
        }
    }
    
    async function loadDataMarque() {
        const Produit = await ProductService.getallMarque();
        if(isSubscribed)  {
          setDataTableMarque(Produit);
          setloadingMarqueTable(false);
        }
    }

    if(loadingCategorieTable)  loadDataCategorie()
    if(loadingMarqueTable) loadDataMarque()
    if (loadingDataTable) loadDataProduit();
    

    if(!loadingDataTable&&!loadingCategorieTable&&!loadingMarqueTable){
      dataGenerate();    
      setLoading(false);
    }
    return () => {
      isSubscribed=false;
    };
  }, [dataTable,dataTableCategorie,dataTableMarque]);

  const actionButton= (keys:Object,categorie:string,marque:string) =>{

    return (
      <div className="actions-right">
        {/* View */}
        <ViewProduct  produit={keys} categorie={categorie} marque={marque} />
        {/* Edition */}
        <Button variant="warning"  onClick={()=>history.push(`/admin/edit_product/${keys['_id']}`) } className="text-warning btn-link edit" >
            <i className="fa fa-edit"/>
        </Button>
      </div>
    )
  }

  const dataGenerate=()=>{
    setData(
       dataTable.map((prop, key) => {
        let categorie=dataTableCategorie.find(obj =>{return obj.value === prop.categorie})
        categorie=(categorie===undefined)?"Inconnu":categorie.label;
        let marque=dataTableMarque.find(obj =>{return obj.value === prop.marque})

        marque=(marque===undefined)?"Inconnu":marque.label;
        
      return {
        id: key,
        categorie: categorie,
        marque:marque,
        ref: prop.reference,
        prix: prop.prix,
        key: prop._id,
        actions: (
          actionButton(prop,categorie,marque)
        ),
      };
    })
  )
}

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          {(loading)?
                    <Spinner animation="border" variant="primary" />
                    :((width< 600)?
                      (<ReactTable 
                          data={data}
                          columns={[
                            {
                              Header: "Reference",
                              accessor: "ref",
                            },
                            {
                              Header: "Actions",
                              Filter:false
                            },
                          ]}
                        />)
                        :(<ReactTable 
                              data={data}
                              columns={[
                                {
                                  Header: "Reference",
                                  accessor: "ref",                                  
                                },
                             
                                {
                                  Header: "Categorie",
                                  accessor: "categorie",
                                  Filter: SelectColumnFilter,
                                  filter: 'includes',
                                  Cell: ({ cell: { value } }) => <Badge variant="secondary">{value}</Badge> 
                                },
                                {
                                  Header: "Marque",
                                  accessor: "marque",
                                  Filter: SelectColumnFilter,
                                  filter: 'includes',
                                  Cell: ({ cell: { value } }) => <Badge variant="secondary">{value}</Badge> 
                                },
                                {
                                  Header: "Actions",
                                  accessor: "actions",
                                  Filter:false
                                },
                              ]}
                            />)
                      )
            }
        </Col>
      </Row>
      </Container>
  );
}
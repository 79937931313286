import { emailValidation, minLength } from "lib/outilsForms";
import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Col,
} from "react-bootstrap";
import  {useHistory } from "react-router-dom";
import AuthService from "services/Api/Controllers/AuthService";


export default function LoginPage() {
  const [, setCardClasses] = React.useState("card-hidden");
  const [loginEmailState, setLoginEmailState] = React.useState(true);
  const [loginPasswordState, setLoginPasswordState] = React.useState(true);
  const [loginEmail, setLoginEmail] = React.useState("");
  const [loginPassword, setLoginPassword] = React.useState("");
  const [errorConnected, seterrorConnected] = React.useState<string>("");
  let history = useHistory();


  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (loginEmailState === true && loginPasswordState === true) {
      
      let rememberMe = 1
      
      return AuthService.signIn(loginEmail, loginPassword, rememberMe)
        .then(auth => {
          if (auth) {
            console.log("Connected")
            seterrorConnected(null);
            history.replace('/admin/dashboard')
          } else {
            //throw new SubmissionError({ _error: 'Votre login ou mot de passe est incorrect' })
            console.log("Mot de passe incorrect")
          }
        })
        .catch(error => {
          console.log("Mot de passe incorrect",error)
          // throw new SubmissionError({ _error: 'Login ou mot de passe incorrect' })
          seterrorConnected("ok");
        })
    }
    else {
      alert("Veuillez remplir tous les champs obligatoires")
    }
  }
  
  return (
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8" style={{ marginTop: "100px" }}>
              <Form method="" onSubmit={handleSubmit}>
                <Card>
                  <Card.Header>
                    <Card.Title as="h4" className="text-center">Connexion</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group
                      className={"has-label " + (loginEmailState ? "has-success" : "has-error")}
                    >
                      <label>
                        Adresse email <span className="star">*</span>
                      </label>
                      <Form.Control
                        name="email"
                        type="text"
                        value={loginEmail}
                        onChange={(e) => {
                          setLoginEmail(e.target.value);
                          if (emailValidation(e.target.value)) {
                            setLoginEmailState(true);
                          } else {
                            setLoginEmailState(false);
                          }
                        }}
                      ></Form.Control>
                      {loginEmailState ? null : (
                        <label className="error">Ce champ est obligatoire.</label>
                      )}
                    </Form.Group>
                    <Form.Group
                      className={"has-label " + (loginPasswordState ? "has-success" : "has-error")}
                    >
                      <label>
                        Mot de passe <span className="star">*</span>
                      </label>
                      <Form.Control
                        name="password"
                        type="password"
                        value={loginPassword}
                        onChange={(e) => {
                          setLoginPassword(e.target.value);
                          seterrorConnected(null);
                          if (minLength(e.target.value, 4)) {
                            setLoginPasswordState(true);
                          } else {
                            setLoginPasswordState(false);
                          }
                        }}
                      ></Form.Control>
                      {loginPasswordState ? null : (
                        <label className="error">Ce champ est obligatoire.</label>
                      )}
                    </Form.Group>
                    <div className="card-category form-category">
                      Champs obligatoires
                  </div>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <Button
                      className="btn-fill btn-wd"
                      variant="info"
                      type="submit"
                      onClick={() => {
                        if (!loginEmailState || !emailValidation(loginEmail)) {
                          setLoginEmailState(false);
                        } else {
                          setLoginEmailState(true);
                        }
                        if (!loginPasswordState || !minLength(loginPassword, 1)) {
                          setLoginPasswordState(false);
                        } else {
                          setLoginPasswordState(true);
                        }
                      }}
                    >
                      Se Connecter
                  </Button>
                  </Card.Footer>
                  {errorConnected ? (
                    <div className="card-category form-category" style={{ color: "red" }}>
                      <span className="star">*</span>
                    Mot de passe incorrect
                    </div>) : null}
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage: "url(" + require("assets/img/full-screen-image-2.jpg").default + ")",
          }}
        >
        </div>
      </div>
  );
}

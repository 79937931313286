import { maxLength } from "lib/outilsForms";
import React, { useEffect, useState } from "react";
import { Row, Col, Button, InputGroup, FormGroup, Form } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import OffreService from "services/Api/Controllers/OffreService";

// eslint-disable-next-line react/display-name
const Step5 = React.forwardRef((props, ref) => {

  const [inputListObservations, setinputListObservations] = useState([{ titre: ""}]);
  const [descriptionState, setdescriptionState] = useState(true);
  const [alertState, setAlertState] = useState(null);
  const [loadingEdit, setloadingEdit] = useState(true)


  useEffect(() => {
    let isSubscribed = true;
    async function loadEditData() {
      if (loadingEdit) {
        setloadingEdit(false)
        //@ts-ignore.
        
        const propalVal = await OffreService.getByID(props?.match?.params?.propal);
        if(isSubscribed)  {
          setinputListObservations(propalVal?.observations)
        }
      }
    }
    if (loadingEdit) {
      if(props?.match?.params?.propal!==undefined){
        loadEditData()
      }else{
        setloadingEdit(false)
      }
    }
    return () => {isSubscribed=false; };
  }, [])

  const handleInputDescriptionChange = (e, index) => {
    setdescriptionState(true);
    const { name, value } = e.target;
    const list = [...inputListObservations];
    if(maxLength(value,250)||value===""){
      list[index][name] = value;
      setinputListObservations(list);
    }else{
      setAlertState(
        <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Vous ne pouvez pas dépasser 250 caractères "
        onConfirm={() => {setAlertState(null)}}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
        >
      </SweetAlert>
      )
    }
  };
  // gérer l'événement de clic du bouton Supprimer
  const handleRemoveDescriptionClick = index => {
    const list = [...inputListObservations];
    list.splice(index, 1);
    setinputListObservations(list);
  };
  // gérer l'événement de clic du bouton Ajouter
  const handleAddDescriptionClick = () => {
      if(inputListObservations.length<6){
      setinputListObservations([...inputListObservations, { titre: ""}]);
      }else{
        setAlertState(
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Vous avez dépassé le nombre maximum d'ajout"
          onConfirm={() => {setAlertState(null)}}
          onCancel={() => setAlertState(null)}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          confirmBtnText="J'ai compris!"
          >
        </SweetAlert>
        )
      }
  };
  const isValidated = () => { 
    const  inputListObservationsValide = (inputListObservations.length===1)?inputListObservations[0].titre!=="":true;
    setdescriptionState(inputListObservationsValide);
    
    return inputListObservationsValide;
  };
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state:{
      listObservation:inputListObservations.filter((data) => data.titre!=="")
    }
  }));

  
  return (
    <>
    {alertState}
    <div className="wizard-step">
      <p className="text-center">
        <b>Veuillez entrez les observations .</b>
        <br/>
        <span style={{fontSize:'small',fontStyle:'italic'}}>Exemple: Récupération de l&apos;ancienne installation</span>
      </p>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
                {/* Description */}
                <Row>
                    <Col md="12">
                      <Form.Label >
                        Titre
                      </Form.Label>
                    </Col>
                    <Col md="12">
                    {inputListObservations.map((x, i) => {
                            return (
                                <Row key={i}>
                                  <Col md="10" >
                                    <InputGroup className="mb-2">
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>{250-x.titre.length}</InputGroup.Text>
                                            </InputGroup.Prepend>
                                      <Form.Control
                                        name="titre"
                                        placeholder="..."
                                        value={x.titre}
                                        onChange={e => handleInputDescriptionChange(e, i)}
                                    />
                                     </InputGroup>
                                     {descriptionState? null : (
                                        <label  className="error" style={{ color: "red", textTransform: "none" }}>
                                          Ce champ ne peut pas etre vide.
                                        </label>
                                       )}
                                  </Col>
                                  {inputListObservations.length !== 1 
                                    && 
                                    <Col md="1" >
                                      <Button
                                        
                                        variant="danger"
                                        onClick={() => handleRemoveDescriptionClick(i)}
                                        >
                                           <span style={{fontSize:"large"}}>-</span>
                                      </Button>
                                      </Col>
                                  }
              
                                  {inputListObservations.length - 1 === i
                                     &&
                                     <Col md="1" > 
                                      <Button
                                        
                                        variant="warning" 
                                        onClick={handleAddDescriptionClick}
                                      >
                                          <span  style={{fontSize:"large"}}>+</span>
                                      </Button>          
                                    </Col>                       
                                  }
                                </Row>
                            );
                          })}
                    </Col>
                  </Row>
          </FormGroup>
        </Col>
      </Row>
      </div>
    </>
  );
});

export default Step5;

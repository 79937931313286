import { capitalize } from 'lib/outilsForms';
import ApiService from '../ApiService';
import AuthService from './AuthService';

class ProductService {
  

  static saveCategorieToDB(data) {
    var Currentname=data.nom.toLowerCase()
    Currentname=capitalize(Currentname);
    return ApiService.post("product/categorie/save",{nom:Currentname,gabari:data.gabarit},AuthService.getToken())
    .then(res=>{
        if (res.status===200){return "ok";}
        if (res.status===500){return "existdeja";}
        else{return "error";} 
    })
    .catch(err=>{
        throw err
    })
  }
  static saveMarqueToDB(data) {
    var Currentname=data.nom.toLowerCase()
    Currentname=capitalize(Currentname);
    return ApiService.post("product/marque/save",{nom:Currentname},AuthService.getToken())
    .then(res=>{
        if (res.status===200){return "ok";}
        if (res.status===500){return "existdeja";}
        else{return "error";} 
    })
    .catch(err=>{
        throw err
    })
  }
  static saveProductToDB(data) {
    
    return ApiService.post("product/save",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200){return res.id;}
        if (res.status===500){return "existdeja";}
        else{return "error";} 
    })
    .catch(err=>{
        throw err
    })
  }

  static getallCategorie(){
    return ApiService.get("product/categorie/getAll","",AuthService.getToken())
    .then(res=>{
        return res;
    })
    .catch(error=>{
      throw error;
    })
  }
  static getallProduct(){
    return ApiService.get("product/getAll","",AuthService.getToken())
    .then(res=>{
        return res;
    })
    .catch(error=>{
      throw error;
    })
  }
  static getallMarque(){
    return ApiService.get("product/marque/getAll","",AuthService.getToken())
    .then(res=>{
        return res;
    })
    .catch(error=>{
      throw error;
    })
  }
  static deleteProduit(id_delete){
    return ApiService.post("product/delete",{id:id_delete},AuthService.getToken())
    .then(res=>{
      if (res.status===200)return true 
      else return false
    })
    .catch(error=>{
      throw error;
    })
  }
  static deleteCategorie(id_delete){
    return ApiService.post("product/categorie/delete",{id:id_delete},AuthService.getToken())
    .then(res=>{
      if (res.status===200)return true 
      else return false
    })
    .catch(error=>{
      throw error;
    })
  }

  static updatePicture(data) {
    return ApiService.putSaveData("product/updatePicture",data,AuthService.getToken())
    .then(res=>{
      console.log(res)
        if (res.status===200){return true;}
        else{return false;} 
       })
    .catch(err=>
        {
          console.log(err)
            throw err
        })
  }
  static getByIDProduct(data){
      return ApiService.post("product/getByID",{select:data},AuthService.getToken())
      .then(res=>{
        return res;
      })
      .catch(error=>{
        throw error;
    })
    
  }
  static getByIDMarque(data){
      return ApiService.post("product/marque/getByID",{select:data},AuthService.getToken())
      .then(res=>{
        return res;
      })
      .catch(error=>{
        throw error;
    })
    
  }
  static updateData(data) {
    return ApiService.put("product/update",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200){return data.idProduct;}
        else{return "error";} 
       })
    .catch(err=>
        {
          console.log(err)
            throw err
        })
  }
  static updateCategorie(data) {
    return ApiService.put("product/categorie/update",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200){return "ok";}
        else{return "error";} 
       })
    .catch(err=>
        {
          console.log(err)
            throw err
        })
  }
  static updateMarque(data) {
    return ApiService.put("product/marque/update",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200){return "ok";}
        else{return "error";} 
       })
    .catch(err=>
        {
          console.log(err)
            throw err
        })
  }
  static getByIDCategorie(data){
    return ApiService.post("product/categorie/getByID",{select:data},AuthService.getToken())
    .then(res=>{
      return res;
    })
    .catch(error=>{
      throw error;
  })
  
}


}

export default ProductService;




const StatusPropal=[
  { value: "1", label: "Brouillon" },
  { value: "2", label: "Envoyée" },
  { value: "3", label: "Décliné" },
  { value: "4", label: "Acceptée" },
];


export default StatusPropal;

  
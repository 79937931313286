import { maxLength } from "lib/outilsForms";
import React, { useEffect, useState } from "react";
import { Row, Col, Button, InputGroup, FormGroup, Form } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import OffreService from "services/Api/Controllers/OffreService";
import { Redirect } from "react-router-dom";
import BDCService from "services/Api/Controllers/BdcService";

// eslint-disable-next-line react/display-name
const Step4 = React.forwardRef((props, ref) => {

  const CurrentIdPpropal=(props.match!==null || props.match!==undefined)?props.match.params.id:undefined;
  const CurrentIdBDC=(props?.match!==null || props?.match!==undefined)?props?.match?.params?.offre:undefined;
  const [inputListObservations, setinputListObservations] = useState([{ titre: ""}]);
  const [descriptionState, setdescriptionState] = useState(true);
  const [alertState, setAlertState] = useState(null);
  const [loadingEdit,setloadingEdit ] = useState(true);
  const [toRedirect, settoRedirect] = useState(false);
  const [allOffre, setallOffre] = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    
    async function loadEditData() {
      if (loadingEdit) {
        setloadingEdit(false)
        //@ts-ignore.
        const OffreEditVal = await OffreService.getByID(CurrentIdPpropal);
        if(isSubscribed)  {
          setallOffre(OffreEditVal)
          if(OffreEditVal?.observations.length!==0) setinputListObservations(OffreEditVal?.observations)
        }
      }
    }
    async function loadDataCurrent() {
      const EditVal = await BDCService.getByID(CurrentIdBDC);
      if(isSubscribed&&loadingEdit)  {
        setallOffre(EditVal?.propal)
        if(EditVal?.observation?.length!==0) setinputListObservations(EditVal?.observation)
        setloadingEdit(false);
      }
    }

    if (loadingEdit) {
      if(CurrentIdPpropal!==undefined &&CurrentIdBDC===undefined){
        loadEditData().catch(()=>{
          setAlertState(
            <SweetAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Vous n'avez pas accès à cette section!"
              onConfirm={() =>{ settoRedirect(true);setAlertState(null)}}
              onCancel={() =>{settoRedirect(true);setAlertState(null)}}
              confirmBtnBsStyle="info"
            >
            </SweetAlert>
          );
        });
      }
      else if(CurrentIdBDC!==undefined){
        loadDataCurrent().catch(()=>{
          setAlertState(
            <SweetAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Vous n'avez pas accès à cette section!"
              onConfirm={() =>{ settoRedirect(true);setAlertState(null)}}
              onCancel={() =>{settoRedirect(true);setAlertState(null)}}
              confirmBtnBsStyle="info"
            >
            </SweetAlert>
          );
        });
      }
    }
    return () => {isSubscribed=false; };
  }, []);
  const handleInputDescriptionChange = (e, index) => {
    setdescriptionState(true);
    const { name, value } = e.target;
    const list = [...inputListObservations];
    if(maxLength(value,113)||value===""){
      list[index][name] = value;
      setinputListObservations(list);
    }else{
    setAlertState(
      <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Vous ne pouvez pas dépasser 113 caractères "
      onConfirm={() => {setAlertState(null)}}
      onCancel={() => setAlertState(null)}
      confirmBtnBsStyle="primary"
      cancelBtnBsStyle="danger"
      confirmBtnText="J'ai compris!"
      >
     </SweetAlert>
    )
  }
  };
  // gérer l'événement de clic du bouton Supprimer
  const handleRemoveDescriptionClick = index => {
    const list = [...inputListObservations];
    list.splice(index, 1);
    setinputListObservations(list);
  };
  // gérer l'événement de clic du bouton Ajouter
  const handleAddDescriptionClick = () => {
      if(inputListObservations.length<6){
      setinputListObservations([...inputListObservations, { titre: ""}]);
      }else{
        setAlertState(
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Vous avez dépassé le nombre maximum d'ajout"
          onConfirm={() => {setAlertState(null)}}
          onCancel={() => setAlertState(null)}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          confirmBtnText="J'ai compris!"
          >
        </SweetAlert>
        )
      }
  };
  const isValidated = () => { 
    const  inputListObservationsValide = (inputListObservations.length===1)?inputListObservations[0].titre!=="":true;
    setdescriptionState(inputListObservationsValide);
    
    return inputListObservationsValide&&allOffre!==null;
  };
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state:{
      listObservation:inputListObservations?.filter((data) => data.titre!=="")
      ,allOffre
    }
  }));
  if(toRedirect) return (<Redirect to="/admin/bdc" />)

  return (
    <>
    {alertState}
    <div className="wizard-step">
      <p className="text-center">
        <b>Veuillez entrez les observations .</b>
        <br/>
      </p>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
                {/* Description */}
                <Row>
                    <Col md="12">
                      <Form.Label >
                        Titre
                      </Form.Label>
                    </Col>
                    <Col md="12">
                    {inputListObservations?.map((x, i) => {
                            return (
                                <Row key={i}>
                                  <Col md="10" >
                                    <InputGroup className="mb-2">
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>{113-x.titre.length}</InputGroup.Text>
                                            </InputGroup.Prepend>
                                      <Form.Control
                                        name="titre"
                                        placeholder="..."
                                        value={x.titre}
                                        onChange={e => handleInputDescriptionChange(e, i)}
                                    />
                                     </InputGroup>
                                     {descriptionState? null : (
                                        <label  className="error" style={{ color: "red", textTransform: "none" }}>
                                          Ce champ ne peut pas etre vide.
                                        </label>
                                       )}
                                  </Col>
                                  {inputListObservations.length !== 1 
                                    && 
                                    <Col md="1" >
                                      <Button
                                        
                                        variant="danger"
                                        onClick={() => handleRemoveDescriptionClick(i)}
                                        >
                                           <span style={{fontSize:"large"}}>-</span>
                                      </Button>
                                      </Col>
                                  }
              
                                  {inputListObservations.length - 1 === i
                                     &&
                                     <Col md="1" > 
                                      <Button
                                        
                                        variant="warning" 
                                        onClick={handleAddDescriptionClick}
                                      >
                                          <span  style={{fontSize:"large"}}>+</span>
                                      </Button>          
                                    </Col>                       
                                  }
                                </Row>
                            );
                          })}
                    </Col>
                  </Row>
          </FormGroup>
        </Col>
      </Row>
      </div>
    </>
  );
});

export default Step4;


import { Button, Form, FormGroup, Modal, Row } from 'react-bootstrap'
import { Input, Label } from 'reactstrap'
import React, { useState, useRef } from 'react';
import JoditEditor from "jodit-react";
import { useHistory } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import MailService from 'services/Api/Controllers/MailService';
import SocieteServices from 'services/Api/Controllers/SocieteServices';
import { emailValidation } from 'lib/outilsForms';

function EmailSend({data,fromSource}) {
    let history = useHistory();
    const editor = useRef(null)
    const [content, setContent] = useState((data === undefined)? '' : data?.content)
    const [email, setemail] = useState((data === undefined) ? '' : data?.email)
    const [subject, setsubject] = useState((data === undefined) ? '' :data?.subject)
    const [type] = useState((data === undefined && data?.type!==undefined ) ? '' :data?.type)
    const [idCurrent] = useState((data === undefined && data?.idCurrent!==undefined ) ? '' :data?.idCurrent)
    const [societe] = useState((data === undefined) ? SocieteServices.getSociete() :data?.societe)
    // eslint-disable-next-line no-unused-vars
    const [show, setshow] = useState(true);
    const [showSweet, setshowSweet] = useState(null);
    const [emailState, setEmailState] = useState(true)

    const toValid=()=>{
        if(content!==''&&email!==''&&subject!==''&&emailValidation(email)) return true;
        else {
            setshowSweet(
                <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Veuillez saisir tous les éléments"
                onConfirm={() => setshowSweet(null)}
                onCancel={() => setshowSweet(null)}
                confirmBtnBsStyle="primary"
                confirmBtnText="J'ai compris"
              >
              </SweetAlert>
            )
            return false;
        }
    }
    const SendEmail=()=>{
          
    const SendEmailFunc= async () =>{
        const data=((idCurrent!==undefined && type !==undefined))?
                {email:email,subject:subject,content:content,societe:societe,type:type,id:idCurrent}:
                {email:email,subject:subject,content:content,societe:societe}
        const valid=await MailService.SendEmail(data);
        if(valid===true){
            setshowSweet(
                <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Envoyer avec Succes"
                onConfirm={() => {redirect();setshowSweet(null)}}
                onCancel={() => {redirect();setshowSweet(null)}}
                confirmBtnBsStyle="primary"
                confirmBtnText="ok"
              >
              </SweetAlert>
            )
        }else{
            setshowSweet(
                <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Une erreur s'est produite"
                onConfirm={() => setshowSweet(null)}
                onCancel={() => setshowSweet(null)}
                confirmBtnBsStyle="primary"
                confirmBtnText="ok"
              >
              </SweetAlert>
            )
        }

      } 
      if(toValid()){
        SendEmailFunc();
    }
    }
    const redirect=()=>{
        if(fromSource==="create"){
            history.replace('')
        }else if(fromSource==="table"){
            window.location.reload(false);
        }else{
            history.replace('dashboard')
        }
    }
    return (
        <>
        {showSweet}
        <Modal
                size="lg"
                show={show}
                dialogClassName="modal-90w"
            >
                <Modal.Header style={{display:'block',textAlign:'center'}}>
                    <h1 className="p-heading1 text-center">Envoyer  un mail</h1>
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <FormGroup className={emailState ? "has-success" : "has-error"}>
                            <Label className="text-muted">Email</Label>
                            <Form.Control
                          name="email"
                          type="text"
                          value={email}
                          onChange={(e) => {
                            setemail(e.target.value);
                            if (emailValidation(e.target.value)) {
                              setEmailState(true);
                            } else {
                              setEmailState(false);
                            }
                          }}
                        ></Form.Control>
                           {emailState ? null : (
                          <label className="error">
                            Ce champ doit être une adresse électronique.
                          </label>
                        )}
                        </FormGroup>
                        <FormGroup id="subject" >
                            <Label className="text-muted">Sujet</Label>
                            <Input
                                type="text"
                                name="subject"
                                className="text-primary"
                                value={subject}
                                onChange={(e) => setsubject(e.target.value)}
                                placeholder="Sujet"
                            />
                         
                        </FormGroup>
                        
                        <FormGroup controlId="formBasicMessage">
                            <Label className="text-muted">Message</Label>
                            <JoditEditor
                                ref={editor}
                                value={content}
                                config={{
                                    readonly: false, language: "fr", buttons: ['source', '|', 'bold', 'strikethrough', 'underline', 'italic', '|', 'ul', 'ol', '|', 'outdent', 'indent', '|', 'fontsize', 'brush', 'paragraph', '|', 'link', '|', 'align', 'undo', 'redo', '|', 'eraser', '|', 'symbol', 'fullsize',
                                    ],
                                }}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => { }}
                            />
                        </FormGroup>
                        <Row style={{ textAlign: "center", display: "inherit" }}>
                            <Button variant="primary"  style={{marginRight:'10px'}}onClick={()=>redirect()}> Annuler </Button>
                            <Button variant="primary" onClick={()=>SendEmail()}> Envoyer </Button>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default EmailSend;
import { isNumber } from "lib/outilsForms";
import React, { useEffect, useState } from "react";
import { Row, Col, InputGroup, FormGroup, Form, Alert } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import PayementStatus from "lib/Constant/Status/Payement.constant";
import TypeOption from "lib/Constant/interface/forms";
import TypeContract from "lib/Constant/Status/TypeContract.constant";
import BDCService from "services/Api/Controllers/BdcService";

// eslint-disable-next-line react/display-name
const Step4 = React.forwardRef((props, ref) => {

  const CurrentIdBDC=(props?.match!==null || props?.match!==undefined)?props?.match?.params?.offre:undefined;
  const [typePayement, settypePayement] = React.useState<TypeOption|undefined>(undefined);
  const [reprise, setreprise] = useState("0")
  const [livraison, setlivraison] = useState("0")
  const [numerobdc, setnumerobdc] = useState("0")
  const [nombresdeloyers, setnombresdeloyers] = useState("0")
  const [priceLoyer, setpriceLoyer] = useState("0")
  const [priceHT, setPriceHT] = useState("0")
  const [installation, setinstallation] = useState("0")
  const [typeContract, settypeContract] = useState<TypeOption|undefined>(undefined);
  const [alertState, setAlertState] = useState(null);

  const [loadingEdit, setloadingEdit] = useState(true)
  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => { setAlertState(null) }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      >
      </SweetAlert>
    )
  }

  const isValidated = () => {
    const isValidate=typeContract!==undefined&&numerobdc!==""&&installation!==""&&livraison!==""&&reprise!==""
    if(typeContract===undefined){
      return false;
    }else if(typeContract.value==="1"){
     return  isValidate&&priceHT!=="";
    }else if(typeContract.value==="2"){
     return  isValidate&&typePayement!==undefined&&nombresdeloyers!==""&&priceLoyer!=="";
    }else if(typeContract.value==="3"){
      return isValidate&&priceHT!==""&&typePayement!==undefined&&nombresdeloyers!==""&&priceLoyer!=="";
    }
  };
  const DataReturn=()=>{
    if(typeContract===undefined){
      return null;
    }else if(typeContract.value==="1"){
      return  {numerobdc:numerobdc,reprise:reprise,livraison:livraison,installation:installation,type:"achat",prixHT:priceHT}
     }else if(typeContract.value==="2"){
      return  {numerobdc:numerobdc,modePayement:typePayement?.label,reprise:reprise,livraison:livraison,installation:installation,nombresdeloyers:nombresdeloyers,type:"location",priceLoyer:priceLoyer};
     }else if(typeContract.value==="3"){
       return {numerobdc:numerobdc,modePayement:typePayement?.label,reprise:reprise,livraison:livraison,installation:installation,nombresdeloyers:nombresdeloyers,type:"hybride",priceLoyer:priceLoyer,prixHT:priceHT};
     }
  }
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state: DataReturn()
  }));
  useEffect(() => {
    let isMounted=true;

    async function loadDataCurrent() {
        const EditVal = await BDCService.getByID(CurrentIdBDC);
        if(isMounted&&loadingEdit)  {
            setnumerobdc(EditVal?.frais?.numerobdc)
            setreprise(EditVal?.frais?.reprise)
            setlivraison(EditVal?.frais?.livraison)
            setlivraison(EditVal?.frais?.installation)
         
            if(EditVal?.frais?.type==="achat"){
              settypeContract(TypeContract[0])
            }else if(EditVal?.frais?.type==="location"){
              settypeContract(TypeContract[1])
              settypePayement(PayementStatus.filter((item:any)=>item.label===EditVal?.frais?.modePayement)[0])
              setnombresdeloyers(EditVal?.frais?.nombresdeloyers)
              setpriceLoyer(EditVal?.frais?.priceLoyer)
            }else if(EditVal?.frais?.type==="hybride"){
              settypeContract(TypeContract[2])
              settypePayement(PayementStatus.filter((item:any)=>item.label===EditVal?.frais?.modePayement)[0])
              setnombresdeloyers(EditVal?.frais?.nombresdeloyers)
              setpriceLoyer(EditVal?.frais?.priceLoyer)
            }
        setloadingEdit(false)
        }
    }

    if(isMounted&&CurrentIdBDC!==undefined&&loadingEdit){
        loadDataCurrent()
    }
   
    if(isMounted){
        if(props['wizardData']?.TARIFICATION!==undefined){
          const _data=props['wizardData']?.TARIFICATION;
          let counter=0;
          _data.map((item:any)=>{
            counter+=parseInt(item.qte)*parseInt(item.prixht)
          })
          setPriceHT(`${counter}`)
      }
    }
    return () => {
      isMounted=false;
    }
  }, [props['wizardData']])

  const priceData=()=>{
    console.log()
    const reprise_=isNaN(parseInt(reprise))?0:parseInt(reprise)
    const livraison_=isNaN(parseInt(livraison))?0:parseInt(livraison)
    const installation_=isNaN(parseInt(installation))?0:parseInt(installation)
    const priceHT_=isNaN(parseInt(priceHT))?0:parseInt(priceHT)
    return reprise_+livraison_+installation_+priceHT_
  }
  const Vente=()=>{
    return (
      <Row>
      <Col md={{ span: 3,offset:1}} >
      <label>Prix Vente H.T:<span className="text-danger">*</span></label>
        <InputGroup className="mb-2" >
          <InputGroup.Prepend>
            <InputGroup.Text>€ H.T</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            placeholder="Prix Vente H.T:"
            value={priceData()}
            disabled
          />
        </InputGroup>
      </Col>
      <Col md={{ span: 3}} >
        <label>T.V.A</label>
        <InputGroup className="mb-2" >
          <InputGroup.Prepend>
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            disabled
            placeholder="T.V.A"
            value="20"
          />
        </InputGroup>
      </Col>
      <Col md={{ span: 3}} >
        <label>Prix Vente T.T.C</label>
        <InputGroup className="mb-2" >
          <InputGroup.Prepend>
            <InputGroup.Text>€ T.T.C</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            disabled
            placeholder="T.V.A"
            value={priceData()+priceData()*0.20}
          />
        </InputGroup>
      </Col>
    </Row>
    )
  }
  const Location=()=>{
    return (   <Row>
      <Col md={{ span: 3,offset:1}} >
        <label>Nombres de loyers<span className="text-danger">*</span></label>
          <InputGroup className="mb-2" >
            <InputGroup.Prepend>
              <InputGroup.Text>Qte</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="Nombres de loyers"
              value={nombresdeloyers}
              onChange={(e) => {
                //@ts-ignore.
                const value = e.target.value;
                if (isNumber(value) || value === "") {
                  setnombresdeloyers(value)
                } else {
                  alertEvent("Vous devez entrer un prix")
                }
              }}
            />
          </InputGroup>

          {nombresdeloyers !== "" ? null : (
            <label className="error" style={{ color: "red", textTransform: "none" }}>
              Ce champ ne peut pas etre vide.
            </label>
          )}
        </Col>
      <Col md={{span:3}}>
        <label style={{textTransform:"none"}}> 
          Periode de payement
        </label>
        <Select
            className="react-select primary"
            name="selectPayement"
            value={typePayement}
            onChange={(value) => settypePayement(value)}
            options={PayementStatus}
            placeholder="Periode de payement"
          />
          {typePayement !== undefined ? null : (
                  <label className="error" style={{ color: "red", textTransform: "none" }}>
                    Ce champ ne peut pas etre vide.
                  </label>
                )}
      </Col>    
      <Col md={{ span: 3}} >
        <label>Loyer de<span className="text-danger">*</span></label>
          <InputGroup className="mb-2" >
            <InputGroup.Prepend>
              <InputGroup.Text>€ H.T</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="Loyer de :"
              value={priceLoyer}
              onChange={(e) => {
                //@ts-ignore.
                const value = e.target.value;
                if (isNumber(value) || value === "") {
                  setpriceLoyer(value)
                } else {
                  alertEvent("Vous devez entrer un prix")
                }
              }}
            />
          </InputGroup>
          {priceLoyer !== "" ? null : (
            <label className="error" style={{ color: "red", textTransform: "none" }}>
              Ce champ ne peut pas etre vide.
            </label>
          )}
        </Col>    
    </Row>
 )
  }
  const VenteAndLocation=()=>{
    return (<>
      {Vente()}
      {Location()}
      </>
    )
  }
  const NumerBDC=()=>{
    return (    <Col md="12">
          <Col md={{ span: 4,offset:4}} >
                  <label>Bon de commande N:<span className="text-danger">*</span></label>
                    <InputGroup className="mb-2" >
                      <InputGroup.Prepend>
                        <InputGroup.Text>BC-</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        placeholder="Frais de reprise	"
                        value={numerobdc}
                        onChange={(e) => {
                          //@ts-ignore.
                          const value = e.target.value;
                          if (isNumber(value) || value === "") {
                            setnumerobdc(value)
                          } else {
                            alertEvent("Vous devez entrer un nombre")
                          }
                        }}
                      />
                    </InputGroup>
                    {numerobdc !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
                  </Col>                                
          </Col>)
  }
  const FraisDiver=()=>{
    return (
      <FormGroup>
      {/* Description */}
      <Row>
        <Col md={{ span: 3, offset: 1 }} >
        <label>Frais de reprise	<span className="text-danger">*</span></label>
          <InputGroup className="mb-2" >
            <InputGroup.Prepend>
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="Frais de reprise	"
              value={reprise}
              onChange={(e) => {
                //@ts-ignore.
                const value = e.target.value;
                if (isNumber(value) || value === "") {
                  setreprise(value)
                } else {
                  alertEvent("Vous devez entrer un prix")
                }
              }}
            />
          </InputGroup>
          {reprise !== "" ? null : (
            <label className="error" style={{ color: "red", textTransform: "none" }}>
              Ce champ ne peut pas etre vide.
            </label>
          )}
        </Col>               
        <Col md={{ span: 3}} >
        <label>Frais de livraison	<span className="text-danger">*</span></label>
          <InputGroup className="mb-2" >
            <InputGroup.Prepend>
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="Frais de livraison"
              value={livraison}
              onChange={(e) => {
                //@ts-ignore.
                const value = e.target.value;
                if (isNumber(value) || value === "") {
                  setlivraison(value)
                } else {
                  alertEvent("Vous devez entrer un prix")
                }
              }}
            />
          </InputGroup>

          {livraison !== "" ? null : (
            <label className="error" style={{ color: "red", textTransform: "none" }}>
              Ce champ ne peut pas etre vide.
            </label>
          )}
        </Col>    
        <Col md={{ span: 3}} >
        <label>Frais d’installation	<span className="text-danger">*</span></label>
          <InputGroup className="mb-2" >
            <InputGroup.Prepend>
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="Frais d’installation	"
              value={installation}
              onChange={(e) => {
                //@ts-ignore.
                const value = e.target.value;
                if (isNumber(value) || value === "") {
                  setinstallation(value)
                } else {
                  alertEvent("Vous devez entrer un prix")
                }
              }}
            />
          </InputGroup>

          {installation !== "" ? null : (
            <label className="error" style={{ color: "red", textTransform: "none" }}>
              Ce champ ne peut pas etre vide.
            </label>
          )}
        </Col>                 
     </Row>
   
    </FormGroup>
    
    )
  }
  const PleaseSelect=()=>{
    return (
      <Row>
      <Col md={"12"}>
        <div className="text-center" style={{ padding: "20px" }}>
          <Alert variant="warning">
            <span style={{ fontSize: "large", fontWeight: "bold" }}><i className="nc-icon nc-bell-55"></i> Vous devez sélectionner le type de contrat</span>
          </Alert>
        </div>
      </Col>
    </Row>
 
    )
  }
  const typedeContract=()=>{
    return (
      <Row>
      <Col md={{ span: 3 ,offset:8}}>  
          <label style={{textTransform:"none"}}> 
          Type de contract
          </label>
          <Select
              className="react-select primary"
              name="selectPayement"
              value={typeContract}
              onChange={(value) => settypeContract(value)}
              options={TypeContract}
              placeholder="Type de contract"
           />
            {typeContract !== undefined ? null : (
                    <label className="error" style={{ color: "red", textTransform: "none" }}>
                      Ce champ ne peut pas etre vide.
                    </label>
                  )}
        </Col>    
      </Row>
    )
  }
  return (
    <>
      {alertState}
      <div className="wizard-step">
        <Row style={{padding: "30px"}}>
          {NumerBDC()}
          <Col md="12">
            {typedeContract()}
             {(typeContract===undefined)?
                  PleaseSelect()
                :(typeContract.value==="2")?
                  Location()
                :(typeContract.value==="1")?
                  Vente()
                :(typeContract.value==="3")?
                  VenteAndLocation()
                  :null 
            }
            {FraisDiver()}
          </Col>
        </Row>
      </div>
    </>
  );
});

export default Step4;

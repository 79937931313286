import ApiService from '../ApiService';
import AuthService from './AuthService';
import SocieteServices from './SocieteServices';

class ProspectService {
  
  static saveToDB(data) {
    return ApiService.post("prospect/save",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200){return res.id;}
        else{return "";} 
       })
    .catch(err=>
        {
            throw err
        })
  }
  static updatePicture(data) {
    return ApiService.putSaveData("prospect/updatePicture",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200){return true;}
        else{return false;} 
       })
    .catch(err=>
        {
          console.log(err)
            throw err
        })
  }
  static updateData(data) {
    return ApiService.put("prospect/update",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200){return "true";}
        else{return "";} 
       })
    .catch(err=>
        {
          console.log(err)
            throw err
        })
  }
  static getAllData(){
    return ApiService.post("prospect/getAll",{societeID:SocieteServices.getSociete()},AuthService.getToken())
    .then(res=>{return res;})
    .catch(error=>{
      throw error;
    })
  }
  static deleteProspect(id_delete){
    return ApiService.post("prospect/delete",{id:id_delete},AuthService.getToken())
    .then(res=>{
      if (res.status===200)return true 
      else return false
    })
    .catch(error=>{
      throw error;
    })
  }
  static getByID(id){
    return ApiService.post("prospect/getByID",{userSelect:id,societeID:SocieteServices.getSociete()},AuthService.getToken())
    .then(res=>{
      return res;
    })
    .catch(error=>{
      throw error;
    })
  }
  static getByIDGuest(id){
    return ApiService.post("prospect/getByIDGuest",{id:id})
    .then(res=>{
      return res;
    })
    .catch(error=>{
      throw error;
    })
  }
}

export default ProspectService;



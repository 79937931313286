import React from "react";

// react-bootstrap components
import {
  Navbar,
  Container,
} from "react-bootstrap";

function AuthNavbar() {
  const [collapseOpen, ] = React.useState(false);
  return (
    <>
      <Navbar
        className="position-absolute w-100"
        expand="lg"
        variant={collapseOpen ? "white" : "transparent"}
      >
        <Container>
          <div className="navbar-wrapper">
            <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              <div className="logo">
            <a
              className="simple-text logo-mini"
              href=""
            >
              {/* <div className="logo-img">
                <img
                  src={require("assets/img/logo.svg").default}
                  alt="react-logo"
                  width="100px"
                />
              </div> */}
            </a>
          </div>
            </Navbar.Brand>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default AuthNavbar;

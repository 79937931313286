import React from "react";
import  {useHistory } from "react-router-dom";
import {
    Card,
    Container,
    Row,
    Col
} from "react-bootstrap";
import TableOffre from "./TableOffre";

export default function AllPropal(){
    let history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const redirect = () => {
        history.push('add_prospect')
      }
    return (

         <Container fluid>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header style={{paddingTop:"20px",paddingLeft:"50px"}}>
                        </Card.Header>
                        <Card.Body>
                            <TableOffre/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        
    )
}
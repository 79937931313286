import { geocodeByPlaceId } from 'react-google-places-autocomplete';



export const  handleAddressAutoComplete= async (autoComplete):object|any=>{
    let return_arr:any={}
    await geocodeByPlaceId(autoComplete.value.place_id)
    .then(results => {
      const address = results[0].address_components;
      let address1 = "";
      let city = "";
      let country = "";
      let postalCode = "";
      for(const component of address) {
        if (component.types.includes("street_number")) {
          address1 = component.long_name;
        }
        if (component.types.includes("route")) {
          address1 = address1 + " " + component.short_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.short_name;
        }
        if (component.types.includes("postal_code")) {
          postalCode = component.short_name;
        }
      }
        return_arr={
          address:address1,
          city:city,
          country:country,
          codepostal:postalCode
        }
    }  
      )
    .catch(error => 
      console.error(error)
      );
     return return_arr;
  }
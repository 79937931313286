import React, {useState, useEffect} from "react";
import {Redirect } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Col,
  Spinner,
} from "react-bootstrap";
import SocieteServices from "services/Api/Controllers/SocieteServices";
import AuthService from "services/Api/Controllers/AuthService";

//Class
const SelectSocietePage=()=> {

  const [SocieteName, setSocieteName] = useState([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [BackToDashboard, setBackToDashboard] = useState<Boolean>(false);

  useEffect(() => {
    let isSubscribed = true;
    async function loadData() {
      const societes = await SocieteServices.getMySocietes();
      if(isSubscribed) {
        setSocieteName(societes);
        setLoading(false)
      }
    }
    loadData();
      return () => {
        isSubscribed = false;
      }
  },[]);

 function saveToLocalStorage(id,nom){
  setTimeout(function() {
    try {
      localStorage.setItem('usedsociete', id);
      localStorage.setItem('societe', nom);
      // const cookies = new Cookies();
      // cookies.set('usedsociete', id);
      // cookies.set('societe', nom);
    } catch(e) {
      console.log("error save to LocalStorage")
    }
    setBackToDashboard(true);
  }, 1000);
}

if(BackToDashboard===true) {return <Redirect to="/admin/dashboard"/>;} 
if(!AuthService.isLogged())  {return <Redirect to="/auth/login"/>;} 
    return (
        <div
          className="full-page lock-page"
          data-color="blue"
          data-image={require("assets/img/bg4.jpg").default}
        >
          <div className="content d-flex align-items-center p-0">
            <Container>
              <Col className="mx-auto" lg="4" md="8">
                <Card className="card-lock text-center card-plain">
                  <Card.Body>
                    <Card.Title as="h4">Sur quelle société voulez-vous travailler?</Card.Title>
                  </Card.Body>
                  <Card.Footer>
                {(loading)?<Spinner animation="border" variant="primary" />:
                    SocieteName.map(item=>(     
                        <Button
                          key={item._id}
                          className="btn-round"
                          onClick={() =>{saveToLocalStorage(item._id,item.nom);}}
                          variant="info"
                          style={{marginRight:"10px"}}
                        >
                          {item.nom}
                        </Button>
                        ))
               }
                  </Card.Footer>
                </Card>
              </Col>
            </Container>
          </div>
          <div
            className="full-page-background"
            style={{
              backgroundImage:"url(" + require("assets/img/bg4.jpg").default + ")",
            }}
          ></div>
        </div>
    );
}
export default SelectSocietePage;

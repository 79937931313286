/* eslint-disable react/display-name */

import React, { useState, useEffect } from "react";
import {useHistory} from 'react-router-dom'
// react-bootstrap components
import {
  Badge,
  Button,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable";
import SelectColumnFilter from "components/ReactTable/SelectColumnFilter";
import OffreService from "../../../services/Api/Controllers/OffreService";
import StatusPropal from "lib/Constant/Status/Propal.constant";
import ProspectService from "services/Api/Controllers/ProspectService";
import SweetAlert from "react-bootstrap-sweetalert";
import { upperCase} from "lib/outilsForms"

export default function TableBDCWait() {


  const [dataTable, setDataTable] = useState([]);
  const [DataTableProspect, setDataTableProspect] = useState([]);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState<Boolean>(true);
  const [loadingProspect, setLoadingProspect] = useState<Boolean>(true);
  const [loadingDataPropalTable, setloadingDataPropalTable] = useState<Boolean>(true);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [alert, setalert] = useState(null);
  let history = useHistory();
  React.useEffect(() => {
    window.addEventListener("resize", () => { setWidth(window.innerWidth) });
    return () => window.removeEventListener("resize", () => { setWidth(window.innerWidth) });
  });

  useEffect(() => {
    let isSubscribed = true;
    async function loadDataProspect() {
      const prospect = await ProspectService.getAllData();
      if (isSubscribed) {
        setDataTableProspect(prospect);
        setLoadingProspect(false);
      }
    }
    async function loadDataPropal() {
      const propal = await OffreService.getallToBdc();
      if (isSubscribed) {
        setDataTable(propal);
        setloadingDataPropalTable(false);
      }
    }

    if (loadingProspect) {
      loadDataProspect();
    }

    if (loadingDataPropalTable) {
      loadDataPropal()
    }


    if (!loadingDataPropalTable && !loadingProspect ) {
      dataGenerate();
      setLoading(false);
    }

    return () => {
      isSubscribed = false
    };
  }, [dataTable, DataTableProspect]);

  // eslint-disable-next-line no-unused-vars
  const actionButton = (keys: string,prospect:any) => {
    return (
      <div className="actions-right">
        <Button
          onClick={() => window.open(`${process.env.REACT_APP_API_BASE_URL}pdfPropal/${keys}.pdf`, "_blank")}
          variant="info"
          style={{fontSize:'x-large',margin:'0'}}

          className="text-info btn-link like"
        >
          <i className="fa fa-eye" />
        </Button>
        {/* Edition */}

        <Button 
          variant="danger" 
          onClick={() => { eventAlert(prospect,keys) }} 
          className="text-warning btn-link like"
          style={{fontSize:'x-large',margin:'0'}}
        >
        <i className="far fa-play-circle"></i>
        </Button>
      </div>
    )
  }

  const dataGenerate = () => {

    setData(
      dataTable.map((prop, key) => {
        const currentProspect = DataTableProspect.filter((item: any) => item._id == prop.prospect)
        return {
          id: key + 1,
          type: prop.type,
          nom: currentProspect[0]['nom'],
          societe: currentProspect[0]['societe'],
          status: StatusPropal.find((o) => o.value === prop.status).label,
          key: prop._id,
          price: prop.sasp[0].pricesp,
          actions: (
            actionButton(prop._id,prop.prospect)
          ),
        };
      })
    )
  }
  const eventAlert = (prospect,propal) => {
    setalert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`Voulez-vous créer un bon de commande pour ${DataTableProspect.filter((item: any) => item._id == prospect)[0]['nom']}?`}
        onConfirm={() => {history.push(`/admin/add_bdc/${propal}`)}}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui !"
        cancelBtnText="Non"
        showCancel
      ></SweetAlert>
    );
  }




  return (
        <Container >
          {alert}
          <Row>
            <Col md="12">
              {(loading) ?
                <Spinner animation="border" variant="primary" />
                : ((width < 600) ?
                  (<ReactTable
                    data={data}
                    columns={[
                      {
                        Header: "Societe",
                        accessor: "societe",
                      }, 
                      {
                        Header: "Type",
                        accessor: "type",
                        Filter: SelectColumnFilter,
                        filter: 'includes',
                        Cell: ({ cell: { value } }) =>
                          <Badge variant="secondary">{upperCase(value)}</Badge>
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        Filter:false
                      },
                    ]}
                  />)
                  : (<ReactTable
                    data={data}
                    columns={[
                      
                      {
                        Header: "Societe",
                        accessor: "societe",
                      },
                      {
                        Header: "Type",
                        accessor: "type",
                        Filter: SelectColumnFilter,
                        filter: 'includes',
                        Cell: ({ cell: { value } }) =>
                          <Badge variant="secondary">{upperCase(value)}</Badge>
                      },
                      {
                        Header: "Valeur",
                        accessor: "price",
                        Cell: ({ cell: { value } }) => `${value} €`
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        Filter:false

                      },
                    ]}
                  />)
                )
              }
            </Col>
          </Row>
        </Container>
 );
}

import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { minLength } from "lib/outilsForms";
import ProductService from "services/Api/Controllers/ProductService";
import SweetAlert from "react-bootstrap-sweetalert";


export default function AddProductsHelper({show,setShow,edit,type}) {
    const [nom, setNom] = useState("");
    const [alert, setAlert] = useState(null);
    const [nomState, setNomState] = useState(true);
    const [loadingEdit, setLoadingEdit] = useState(true);
    const [response, setresponse] = useState("");

    useEffect(()=>{

    if(response==="ok"){
      setAlert(  
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={(edit==="")?"L'ajout a été effectué avec succès":"La modification a été effectué avec succès"}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Fermer"
        >
        </SweetAlert>
        )
      }
      if(response==="existdeja"){
        setAlert(  
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Le nom existe deja"
            onConfirm={() => hideAlertexist()}
            onCancel={() => hideAlertexist()}
            confirmBtnBsStyle="primary"
            confirmBtnText="Suivant"
          >
          </SweetAlert>
          )
        }
      if(response==="error"){
        setAlert(  
          <SweetAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Une erreur c'est produite!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="primary"
            confirmBtnText="Fermer"
          >
          </SweetAlert>
          )
        }
    },[response]);

    useEffect(() => {

    let isSubscribed = true;

    if(edit!==''){
      if(loadingEdit){
        loadEditData();
      }
    }
    async function loadEditData() {
      if (loadingEdit) {
        if(type==="marque"){
        const marqueEdit = await ProductService.getByIDMarque(edit);
          if(isSubscribed)  {
            setLoadingEdit(false)
            setNom(marqueEdit.nom)
          }
        }else if (type==="categorie"){
          const CategorieEdit = await ProductService.getByIDCategorie(edit);
          if(isSubscribed)  {
            setLoadingEdit(false)
            setNom(CategorieEdit.nom)
          }
        }
      }
    }
    return () => {isSubscribed=false; };
    }, [])
    const onSubmit=()=>{

    if(minLength(nom,2)){setNomState(true);}else{ setNomState(false)}

    if(minLength(nom,2)){
        if(edit===""){
        SubmitToBack();
        }else{
          UpdateToBack();
        }
      }
    }
    const SubmitToBack=()=>{
    const datas={nom:nom}
    async function data() {
      
        if(type==="marque"){
          const boolrest =await ProductService.saveMarqueToDB(datas)
          setresponse(boolrest);
          }else if (type==="categorie"){
            const boolrest =await ProductService.saveCategorieToDB(datas)
            setresponse(boolrest);
          }else{
            const boolrest ="error";
            setresponse(boolrest);
          }
        }
    
    data();
    }
    const UpdateToBack=()=>{
    const datas={nom:nom,id:edit}
    async function data() {
      if(type==="marque"){
        const boolrest =await ProductService.updateMarque(datas)
        setresponse(boolrest);
        }else if (type==="categorie"){
          const boolrest =await ProductService.updateCategorie(datas)
          setresponse(boolrest);
        }else{
          const boolrest ="error";
          setresponse(boolrest);
        }
    }
    data();
    }
    const hideAlert = () => {
      setAlert(null);
      setShow(false);
    };
    const hideAlertexist = () => {
      setAlert(null);
    };

    return (
    <>
    {alert}
      <Modal
          size="lg"
          show={show}
          dialogClassName="modal-90w"
          
        >
          <Modal.Header>
          </Modal.Header>
          <Modal.Body>
          <Row>
              <Col md="12" style={{ textAlign: 'center', marginBottom: '30px' }}>
               {(edit==="")?<h3>Ajouter une {type}</h3>:<h3>Modifier une {type}</h3>}
             </Col>
             </Row>
            {/* Nom */}
            <Row>
                    <Form.Label column sm={{ span: 2, offset: 1 }}>
                    Nom
                    </Form.Label>
                    <Col sm="7">
                      <Form.Group className={nomState ? "has-success" : "has-error"}>
                          <Form.Control
                            name="Nom"
                            type="text"
                            value={nom}
                            onChange={(e) => {
                              setNom(e.target.value);
                              if (minLength(e.target.value,2)) {
                                setNomState(true);
                              } else {
                                setNomState(false);
                              }
                            }}
                          >
                          </Form.Control>
                        {nomState ? null : (
                        <label className="error" style={{ color: "red", textTransform: "none" }}>
                          Ce champ doit être un nom.
                        </label>
                         )}
                    </Form.Group>
                </Col>
              </Row>
            <Row style={{textAlign: 'center',marginTop:'15px'}}>
              <Col>
               <Button
                variant="danger"
                  onClick={()=>{ setShow(false)}}
                >
                  Fermer
                </Button>
                {(edit==="")?(<Button
                style={{marginLeft:'10px'}}
                  onClick={onSubmit}
                >
                  Ajouter
                </Button>)
                :(<Button
                variant="warning"
                  style={{marginLeft:'10px'}}
                    onClick={onSubmit}
                  >
                    Modifier
                  </Button>)
                }
              </Col> 
            </Row>
          </Modal.Body>
        </Modal>
      </>
    )
}

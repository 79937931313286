import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, FormGroup, Button } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import OffreService from "services/Api/Controllers/OffreService";
import ProductService from "services/Api/Controllers/ProductService";


// eslint-disable-next-line react/display-name
const Step3 = React.forwardRef((props, ref) => {

  const [inputAllSelectedProduct, setinputAllSelectedProduct] = useState([{ selectCategorie: [], selectMarque: [], selectRef: [], selectMulti: [] }]);
  const [productErrors, setproductErrors] = useState(true);

  const [dataTableCategorie, setDataTableCategorie] = useState([]);
  const [dataTableMarque, setDataTableMarque] = useState([]);
  const [dataTableAllProduct, setdataTableAllProduct] = useState([]);
  const [loadingProduit, setloadingProduit] = useState(true);
  const [loadingCategorieTable, setloadingCategorieTable] = useState<Boolean>(true);
  const [loadingMarqueTable, setloadingMarqueTable] = useState<Boolean>(true);

  const [loadingEdit, setloadingEdit] = useState(true)
  const [alertState, setAlertState] = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    async function loadEditData() {
      if (loadingEdit) {
        setloadingEdit(false)
        //@ts-ignore.
        
        const propalVal = await OffreService.getByID(props?.match?.params?.propal);
        if(isSubscribed)  {
          console.log("testInput",propalVal)
          setinputAllSelectedProduct(propalVal?.helpDuplicate)
        }
      }
    }
    if (loadingEdit) {
      if(props?.match?.params?.propal!==undefined){
        loadEditData()
      }else{
        setloadingEdit(false)
      }
    }
    return () => {isSubscribed=false; };
  }, [])
  

  useEffect(() => {
    let isSubscribed = true;

    async function loadDataProduit() {
      const Produit = await ProductService.getallProduct();
      if (isSubscribed) {
        setdataTableAllProduct(Produit);
        setloadingProduit(false);
      }
    }
    async function loadDataCategorie() {
      const Produit = await ProductService.getallCategorie();
      if (isSubscribed) {
        setDataTableCategorie(Produit);
        setloadingCategorieTable(false);
      }
    }

    async function loadDataMarque() {
      const Produit = await ProductService.getallMarque();
      if (isSubscribed) {
        setDataTableMarque(Produit);
        setloadingMarqueTable(false);
      }
    }

    if (loadingCategorieTable) loadDataCategorie()
    if (loadingMarqueTable) loadDataMarque()
    if (loadingProduit) loadDataProduit();

    return () => {
      setloadingMarqueTable(true);
      setloadingProduit(true);
      setloadingCategorieTable(true);
      isSubscribed = false;
    }

  }, [])


  const handleInputSelected = (e, index, name) => {
    setproductErrors(true);
    const list = [...inputAllSelectedProduct];
    list[index][name] = e;
    setinputAllSelectedProduct(list);
  };
  const handleInputSelectedRef = (e, index, name) => {
    setproductErrors(true);
    const list = [...inputAllSelectedProduct];
    let ismultiple = 0;
    dataTableAllProduct.filter(acc => (acc['_id'] === e.value)).map((item) => (item['description']
      .map((val) => ((val.ismultiple) ? ismultiple++ : null))))
    Object.assign(e, { isMultiple: ismultiple })

    list[index][name] = e;


    setinputAllSelectedProduct(list);
  };
  const handleInputSelectedMutli = (e, index, name, keys) => {
    setproductErrors(true);
    const list = [...inputAllSelectedProduct];
    list[index][name][keys] = e;
    setinputAllSelectedProduct(list);
  };
  // gérer l'événement de clic du bouton Supprimer
  const handleRemoveDescriptionClick = index => {
    const list = [...inputAllSelectedProduct];
    list.splice(index, 1);
    setinputAllSelectedProduct(list);
  };
  // gérer l'événement de clic du bouton Ajouter
  const handleAddDescriptionClick = () => {
    setinputAllSelectedProduct([...inputAllSelectedProduct, { selectCategorie: [], selectMarque: [], selectRef: [], selectMulti: [] }]);
  };
  const isValidated = () => {
    const inputAllSelectedProductValide = (inputAllSelectedProduct.length === 1) ?
      (inputAllSelectedProduct[0].selectMarque.length !== 0
        && inputAllSelectedProduct[0].selectCategorie.length !== 0
        && inputAllSelectedProduct[0].selectRef.length !== 0
      ) : null;

    if (inputAllSelectedProduct.length === 1) {
      if (!inputAllSelectedProductValide) alertEvent("Vous avez sélectionné un produit que vous n'avez pas completé");
    }

    var IsValidate = true;
    inputAllSelectedProduct.forEach(item => (
      (item.selectMarque.length !== 0 && item.selectCategorie.length !== 0 && item.selectRef.length !== 0) ? null : IsValidate = false
    )
    );

    var isEmpty = true;
    if (!IsValidate) {
      if (inputAllSelectedProduct.length > 1) {
        inputAllSelectedProduct.forEach((item, index) => (
          (index > 0) ?
            (item.selectMarque.length === 0 && item.selectCategorie.length === 0 && item.selectRef.length === 0) ? isEmpty = false : null
            : null
        ));
        if (!isEmpty) alertEvent("Vous avez sélectionné un produit que vous n'avez pas completé");
        else alertEvent("Vous avez sélectionné un produit que vous n'avez pas terminé");
      }
    }

    let counter = 0;
    inputAllSelectedProduct.forEach(item => {
      if (item.selectMarque.length !== 0 && item.selectCategorie.length !== 0 && item.selectRef.length !== 0) {
        counter = 0;
        item.selectMulti.forEach(multiCount => { if (multiCount !== null) counter++ })
        if (counter !== item.selectRef.isMultiple) IsValidate = false;
      }
    });

    if (inputAllSelectedProduct.length === 1) {
      setproductErrors(inputAllSelectedProductValide && IsValidate)
      return inputAllSelectedProductValide && IsValidate;
    }

    setproductErrors(IsValidate);

    return IsValidate;
  };

  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => { setAlertState(null) }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      >
      </SweetAlert>
    )
  }

  const returnData = () => {
    return inputAllSelectedProduct
    .map((data) => {
      let description = []
      if (data.selectRef.length !== 0&&data.selectMulti.length!==0) {
        dataTableAllProduct
          .filter(acc => (acc['_id'] === data.selectRef.value))
          .map((item) => (item['description'].map((val, keys) => {
            if (!val.ismultiple) description.push({ titre: val.titre, description: val.description })
            else { description.push({ titre: data.selectMulti[keys]?.value,description:"" }) }
          })))
      }else{
        dataTableAllProduct
        .filter(acc => (acc['_id'] === data.selectRef.value))
        .map((item) => (item['description'].map((val) => (
           description.push({ titre: val.titre, description: val.description })))))
      }
   return new Object({//@ts-ignore.
      selectRef: data.selectRef.value,
      description:description
    })
  });
}

React.useImperativeHandle(ref, () => ({
  isValidated: () => {
    return isValidated();
  },
  state: {
    listProduit: returnData(),
    helpDuplicate:inputAllSelectedProduct
  }
}));
return (
  <>
    {alertState}
    <div className="wizard-step">
      <p className="text-center">
        <b>Veuillez sélectionner les produits à afficher sur la proposition.</b>
        <br />
      </p>
      <FormGroup>
        {/* Description */}
        {inputAllSelectedProduct.map((x, i) => {
          return (
            <Row key={i}>
              <Col md={{ span: 3, offset: 1 }}>
                <label>Categorie <span className="text-danger">*</span></label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="selectCategorie"
                  value={x.selectCategorie}
                  onChange={e => handleInputSelected(e, i, "selectCategorie")}
                  options={dataTableCategorie}
                  placeholder="Categorie"
                />
                {(productErrors || x.selectCategorie.length !== 0) ? null : (
                  <label className="error " style={{ color: "red", textTransform: "none" }}>
                    Ne peut pas etre vide.
                  </label>
                )}
              </Col>
              <Col md="3">
                <label>Marque <span className="text-danger">*</span></label>
                <Select
                  isDisabled={!(x.selectCategorie.length !== 0)}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="selectMarque"
                  value={x.selectMarque}
                  onChange={e => handleInputSelected(e, i, "selectMarque")}
                  options={dataTableMarque}
                  placeholder="Marque"
                />
                {(productErrors || x.selectMarque.length !== 0) ? null : (
                  <label className="error " style={{ color: "red", textTransform: "none" }}>
                    Ne peut pas etre vide.
                  </label>
                )}
              </Col>
              <Col md="3">
                <label>Reference <span className="text-danger">*</span></label>
                <Select
                  isDisabled={!(x.selectMarque.length !== 0 && x.selectCategorie.length !== 0)}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="selectRef"
                  value={x.selectRef}
                  onChange={e => handleInputSelectedRef(e, i, "selectRef")}
                  options={
                    dataTableAllProduct
                      .filter(acc => (acc['categorie'] === x.selectCategorie.value && acc['marque'] === x.selectMarque.value))
                      .map(response => new Object({ value: response._id, label: response.reference }))
                  }
                  placeholder="Reference"
                />
                {(productErrors || x.selectRef.length !== 0) ? null : (
                  <label className="error " style={{ color: "red", textTransform: "none" }}>
                    Ne peut pas etre vide.
                  </label>
                )}
              </Col>
              <Col md={{ offset: 1, span: 9 }}>
                {
                  dataTableAllProduct
                    .filter(acc => (acc['_id'] === x.selectRef.value))
                    .map((item) => (item['description'].map((val, keys) => (
                      (val.ismultiple) ?
                        <Fragment key={keys}>
                          <div style={{ marginTop: '10px' }}>
                            <Select
                              isDisabled={!(x.selectMarque.length !== 0 && x.selectCategorie.length !== 0 && x.selectRef.length !== 0)}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="selectMulti"
                              value={x.selectMulti[keys]}
                              onChange={e => {
                                handleInputSelectedMutli(e, i, "selectMulti", keys)
                              }}
                              options={val['tag'].map(response => new Object({ value: response, label: response }))
                              }
                              placeholder="Choix Multiple"
                            />
                          </div>
                          {(productErrors) ? null : (
                            <label className="error " style={{ color: "red", textTransform: "none" }}>
                              Ne peut pas etre vide.
                            </label>
                          )}
                        </Fragment>
                        : null
                    )
                    ))
                    )
                }
              </Col>

              <Col className="mb-2 text-right" md="12" >
                <div style={{ marginTop: '20px' }}>
                  {inputAllSelectedProduct.length !== 1
                    &&
                    <Button
                      variant="warning"
                      onClick={() => handleRemoveDescriptionClick(i)}
                    >
                      <span style={{ fontSize: "large" }}>-</span>
                    </Button>
                  }
                  {inputAllSelectedProduct.length - 1 === i
                    &&
                    <Button
                      style={{ marginLeft: "10px" }}
                      variant="info"
                      onClick={handleAddDescriptionClick}
                    >
                      <span style={{ fontSize: "large" }}>+</span>
                    </Button>
                  }
                </div>
              </Col>
            </Row>
          );
        })}
      </FormGroup>
    </div>
  </>
);


});

export default Step3;

/* eslint-disable react/display-name */
import React,{useState,useEffect} from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable";
import ProspectService from "services/Api/Controllers/ProspectService";
import StatusProspect from "lib/Constant/Status/Propsect.constant";
import SelectColumnFilter from "components/ReactTable/SelectColumnFilter";
import AuthService from "../../services/Api/Controllers/AuthService"
import ViewProspect from "./ViewProspect";
import { format } from 'date-fns';
import {useHistory} from "react-router-dom";
import OffreService from "../../services/Api/Controllers/OffreService";
import ColorBadge from "lib/Constant/Status/ColorBadge.constant";
export default function TableProspect() {


  const [dataTable,setDataTable]=useState([]);
  const [dataTableoffre,setDataTableoffre]=useState([]);
  const [data,setData]=useState([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [loadingUserName, setloadingUserName] = useState<Boolean>(true);
  const [loadingDataTable, setloadingDataTable] = useState<Boolean>(true);
  const [loadingDataoffreTable, setloadingDataoffreTable] = useState<Boolean>(true);

  const [nameUser, setNameUser] = React.useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);

  let history = useHistory();

  React.useEffect(() => {
    window.addEventListener("resize", () => {setWidth(window.innerWidth)});
    return () => window.removeEventListener("resize", () => {setWidth(window.innerWidth)});
  });
  
  useEffect(() => {
    let isSubscribed = true;
    async function loadDataUserName() {
      let username = await AuthService.getnameid();
      if(isSubscribed)  {
        setNameUser(username);
        setloadingUserName(false);
      }
    }
    async function loadDataProspect() {
        const prospect = await ProspectService.getAllData();
        if(isSubscribed)  {
          setDataTable(prospect);
          loadDataUserName();
          setloadingDataTable(false);
        }

    }
    async function loadDataoffre() {
      const offre = await OffreService.getAllOffre();
      if(isSubscribed)  {
        setDataTableoffre(offre); 
        setloadingDataoffreTable(false);
      }
    }
    if(loadingUserName){
      loadDataUserName();
    }
    if (loadingDataTable) {
      loadDataProspect();
    }
    if (loadingDataoffreTable) {
      loadDataoffre()
    }

    if(!loadingDataTable&&!loadingUserName&&!loadingUserName&&!loadingDataoffreTable){
      dataGenerate();    
      setLoading(false);
    }
    return () => {
      isSubscribed=false;
    };
    
  }, [dataTable,nameUser,dataTableoffre]);

  const actionButton= (keys:string,attributeAtt:string,nboffrepropal:number,nboffresasp:number) =>{

    return (
      <div className="actions-right">
        {/* View */}
        <ViewProspect  nameAttribute={nameUser.find((o) => o._id === attributeAtt)} prospect={dataTable.find((o) => o._id === keys)} 
        nbOffrePropal={nboffrepropal} nbOffreSaSp={nboffresasp}/>
        {/* Edition */}
          <Button variant="warning"  onClick={()=>history.push(`/admin/edit_prospect/${dataTable.find((o) => o._id === keys)._id}`) } className="text-warning btn-link edit" >
            <i className="fa fa-edit"/>
          </Button>
        </div>
    )
  }

  const dataGenerate=()=>{
  
    setData(
       dataTable.map((prop, key) => {
         let nameAttribute= nameUser.find((o) => o._id === prop.attributeAtt)
         const nboffre=dataTableoffre.filter((item:any)=>(item.prospect==prop._id)).length
         const nboffrePropal=dataTableoffre.filter((item:any)=>(item.prospect==prop._id)&&(item.type==="propal")).length
         const nboffresasp=dataTableoffre.filter((item:any)=>(item.prospect==prop._id)&&(item.type==="sasp")).length

      return {
        id: key,
        nom: prop.nom,
        prenom: prop.prenom,
        societe: prop.societe,
        nboffre:nboffre,
        status: StatusProspect.find((o) => o.value === prop.status).label,
        attributeAtt:(nameAttribute!==undefined)?(nameAttribute.prenom.concat(" ").concat(nameAttribute.nom)):("Non Disponible"),
        modifierle:new Date(prop.lastModifiedAt),
        key: prop._id,
        actions: (
          actionButton(prop._id,prop.attributeAtt,nboffrePropal,nboffresasp)
        ),
      };
    })
  )
}

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          {(loading)?
                    <Spinner animation="border" variant="primary" />
                    :((width< 600)?
                      (<ReactTable 
                          data={data}
                          columns={[
                            {
                              Header: "Nom",
                              accessor: "nom",
                            },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              Filter:false
                            },
                          ]}
                        />)
                        :(<ReactTable 
                              data={data}
                              columns={[
                                {
                                  Header: "Nom",
                                  accessor: "nom",                                  
                                },
                                {
                                  Header: "Prenom",
                                  accessor: "prenom",
                                },
                                {
                                  Header: "Societe",
                                  accessor: "societe",
                                },
                                {
                                  Header: "Status",
                                  accessor: "status",
                                  Filter: SelectColumnFilter,
                                  filter: 'includes',
                                  Cell: ({ cell: { value } }) => 
                                  <Badge variant={`${ColorBadge(value)}`}>{value}</Badge>
                                },
                                {
                                  Header: "Modifier le",
                                  accessor: "modifierle",
                                  Cell: ({ cell: { value } }) =>  format(new Date(value), 'dd/MM/yyyy') 
                                },
                                {
                                  Header: "Attribué à",
                                  accessor: "attributeAtt",
                                  Filter: SelectColumnFilter,
                                  filter: 'includes',
                                  Cell: ({ cell: { value } }) => <Badge variant="secondary">{value}</Badge> 
                                },
                                {
                                  Header: "Offre",
                                  accessor: "nboffre",
                                  Filter: SelectColumnFilter,
                                  Cell: ({ cell: { value } }) => 
                                  <Badge variant="info">{value}</Badge>
                                },
                                {
                                  Header: "Actions",
                                  accessor: "actions",
                                  Filter:false
                                },
                              ]}
                            />)
                      )
            }
        </Col>
      </Row>
      </Container>
  );
}


const StatusSource=[
  { value: "1", label: "Facebook" },
  { value: "2", label: "Linkedin" },
  { value: "3", label: "Google" },
  { value: "4", label: "Autre" },
];


export default StatusSource;

/* eslint-disable react/display-name */
import React,{useState,useEffect} from "react";
import {
  Badge,
  Container,
  Row,
  Col,
  Spinner,
  Button,
} from "react-bootstrap";
import {capitalize} from "../../lib/outilsForms"

// core components
import ReactTable from "components/ReactTable/ReactTable";

import AuthService from "services/Api/Controllers/AuthService";
import StatusUser from "lib/Constant/Status/User.constant";
import SocieteConstant from "lib/Constant/Status/Societe.constant";
import SweetAlert from "react-bootstrap-sweetalert";

export default function TableUser() {


  const [dataTableUser,setDataTableUser]=useState([]);
  const [data,setData]=useState([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [loadingTableUser, setloadingTableUser] = useState<Boolean>(true); 
  const [width, setWidth] = React.useState(window.innerWidth);
  const [alert, setalert] = useState(null);
  const [response, setresponse] = useState(null)
  React.useEffect(() => {
    window.addEventListener("resize", () => {setWidth(window.innerWidth)});
    return () => window.removeEventListener("resize", () => {setWidth(window.innerWidth)});
  });
  
  useEffect(() => {
    let isSubscribed = true;
    async function loadData() {
        const Produit = await AuthService.getAll();
        if(isSubscribed)  {
          setDataTableUser(Produit);
          setloadingTableUser(false);
        }
    }

    if(loadingTableUser)  loadData()

    if(!loadingTableUser){
      dataGenerate();    
      setLoading(false);
    }
    return () => {
      isSubscribed=false;
    };
  }, [dataTableUser]);
   
  const UpdateisValidated = (id, password) => {
   
    const datas = { id: id, password:password}
    async function data() {
      const boolrest = await AuthService.UpdatePasswordbyId(datas)
      setresponse(boolrest);
    }
    data();
  }

  useEffect(() => {
    if (response === true) {
      setalert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={"Votre demande a été enregistrée"}
          onConfirm={() => setalert(null)}
          onCancel={() =>setalert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Fermer"
        >
        </SweetAlert>
      )
    } if (response === false) {
      setalert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Une erreur s'est produite ! Veuillez réessayer plus tard ..."
          onConfirm={() => setalert(null)}
          onCancel={() => setalert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Fermer"
        >
        </SweetAlert>
      )
    }
  }, [response])

const ChangePassword=(nom,id)=>{
  setalert(
    <SweetAlert
      input
      style={{ display: "block", marginTop: "-100px" }}
      title={`Changer le mot de passe de ${nom}`}
      onConfirm={(e) => {
        setalert(null);
        UpdateisValidated(id, e)
      }}
      onCancel={() => setalert(null)}
      confirmBtnBsStyle="primary"
      cancelBtnBsStyle="danger"
      confirmBtnText="Changer"
      cancelBtnText="Annuler"
      showCancel
    >
    Veuillez entrer un nouveau mot de passe :
    <br></br> <br></br>
   
    </SweetAlert>)
}
  const actionButton= (keys:string,nom:any) =>{
    return (
      <div className="actions-right">
          <Button variant="warning"  onClick={()=>ChangePassword(nom,keys)} className="text-warning btn-link edit" >
            <i className="fa fa-edit"/>
          </Button>
        </div>
    )
  }
  const dataGenerate=()=>{
    
    
    setData(
      dataTableUser.map((prop, key) => {
        SocieteConstant
      let data="";prop.societes.map(item=>data+=SocieteConstant.filter(items=>items.value==item)[0].label+" ")
      const nom=`${capitalize(prop.nom)} ${capitalize(prop.prenom)}`;
      return {id:key,
        key:prop._id,
        nom:nom ,
        societes:data,
        email:prop.email,
        adminLevel:prop.adminLevel,
        lastConnection:prop.lastConnection,
        actions: (
          actionButton(prop._id,nom)
        ),
      };
    })
  )
}

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md="12">
          {(loading)?
                    <Spinner animation="border" variant="primary" />
                    :((width< 600)?
                      (<ReactTable 
                          data={data}
                          columns={[
                            {
                              Header: "Nom",
                              accessor: "nom",
                            }
                          ]}
                        />)
                        :(<ReactTable 
                              data={data}
                              columns={[
                                {
                                  Header: "Nom",
                                  accessor: "nom",                                  
                                },
                                {
                                  Header: "Email",
                                  accessor: "email",                                  
                                },
                                {
                                  Header: "Societe",
                                  accessor: "societes",
                                  Cell: ({ cell: { value } }) => <Badge variant="secondary">{value}</Badge> 
                                },
                                {
                                  Header: "Niveau",
                                  accessor: "adminLevel",
                                  Cell: ({ cell: { value } }) => <Badge variant="secondary">{StatusUser.filter(item=>item.value==value)[0].label}</Badge> 
                                },
                                {
                                  Header: "Mot de passe",
                                  accessor: "actions",
                                  Filter:false
                                }
                              ]}
                            />)
                      )
            }
        </Col>
      </Row>
      </Container>
  );
}

import { maxLength } from "lib/outilsForms";
import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup,Form, Button, InputGroup } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import OffreService from "services/Api/Controllers/OffreService";


// eslint-disable-next-line react/display-name
const Step2 = React.forwardRef((props, ref) => {

  const [inputListDescription, setinputListDescription] = useState([{ titre: ""}]);
  const [descriptionState, setdescriptionState] = useState(true);
  const [alertState, setAlertState] = useState(null);
  const [loadingEdit, setloadingEdit] = useState(true)

  useEffect(() => {
    let isSubscribed = true;
    async function loadEditData() {
      if (loadingEdit) {
        setloadingEdit(false)
        //@ts-ignore.
        
        const propalVal = await OffreService.getByID(props?.match?.params?.propal);
        if(isSubscribed)  {
          setinputListDescription(propalVal?.sujets)
        }
      }
    }
    if (loadingEdit) {
      if(props?.match?.params?.propal!==undefined){
        loadEditData()
      }else{
        setloadingEdit(false)
      }
    }
    return () => {isSubscribed=false; };
  }, [])

  const handleInputDescriptionChange = (e, index) => {
  setdescriptionState(true);
  const { name, value } = e.target;
  const list = [...inputListDescription];
  if(maxLength(value,80)|| value===""){
    list[index][name] = value;
    setinputListDescription(list);
  }else{
    setAlertState(
      <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Vous ne pouvez pas dépasser 80 caractères "
      onConfirm={() => {setAlertState(null)}}
      onCancel={() => setAlertState(null)}
      confirmBtnBsStyle="primary"
      cancelBtnBsStyle="danger"
      confirmBtnText="J'ai compris!"
      >
      </SweetAlert>
    )
  }
  };
  // gérer l'événement de clic du bouton Supprimer
  const handleRemoveDescriptionClick = index => {
  const list = [...inputListDescription];
  list.splice(index, 1);
  setinputListDescription(list);
  };
  // gérer l'événement de clic du bouton Ajouter
  const handleAddDescriptionClick = () => {
    if(inputListDescription.length<4){
    setinputListDescription([...inputListDescription, { titre: ""}]);
    }else{
      setAlertState(
        <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Vous avez dépassé le nombre maximum d'ajout"
        onConfirm={() => {setAlertState(null)}}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
        >
        </SweetAlert>
      )
    }
  };
  const isValidated = () => { 
    const  inputListDescriptionValide = (inputListDescription.length===1)?inputListDescription[0].titre!=="":true;
    setdescriptionState(inputListDescriptionValide);
    
    return inputListDescriptionValide;
  };
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state:{
      listSujet:inputListDescription.filter((data) => data.titre!=="")
    }
  }));

  return (
    <>
    {alertState}
    <div className="wizard-step">
      <p className="text-center">
        <b>Veuillez entrez les sujets de la propale.</b>
        <br/>
        <span style={{fontSize:'small',fontStyle:'italic'}}>Exemple: Remplacement du photocopieur « principale »</span>
      </p>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
                {/* Description */}
                <Row>
                    <Col md="12">
                      <Form.Label >
                        Sujet
                      </Form.Label>
                    </Col>
                    <Col md="12">
                      {inputListDescription.map((x, i) => {
                          return (
                              <Row key={i}>
                                <Col md="10" >
                                  <InputGroup className="mb-2">
                                      <InputGroup.Prepend>
                                        <InputGroup.Text>{80-x.titre.length}</InputGroup.Text>
                                      </InputGroup.Prepend>
                                    <Form.Control
                                      name="titre"
                                      placeholder="..."
                                      value={x.titre}
                                      onChange={e => handleInputDescriptionChange(e, i)}
                                    />
                                    </InputGroup>
                                    {descriptionState? null : (
                                      <label  className="error" style={{ color: "red", textTransform: "none" }}>
                                        Ce champ ne peut pas etre vide.
                                      </label>
                                      )}
                                </Col>
                                {inputListDescription.length !== 1 
                                  && 
                                  <Col md="1" >
                                    <Button
                                      variant="warning"
                                      onClick={() => handleRemoveDescriptionClick(i)}
                                      >
                                          <span style={{fontSize:"large"}}>-</span>
                                    </Button>
                                  </Col>
                                }
                                {inputListDescription.length - 1 === i
                                    &&
                                    <Col md="1" > 
                                    <Button
                                      variant="info" 
                                      onClick={handleAddDescriptionClick}
                                    >
                                        <span  style={{fontSize:"large"}}>+</span>
                                    </Button>          
                                  </Col>                       
                                }
                              </Row>
                          );
                        })}
                    </Col>
                  </Row>
          </FormGroup>
        </Col>
      </Row>
      </div>
    </>
  );


});

export default Step2;

import React, {useState } from "react";
import  {useHistory } from "react-router-dom";
import {
    Button,
    Card,
    Container,
    Row,
    Col,
} from "react-bootstrap";

import TableProduit from "./TableProduit";
import AddProductsHelper from "components/Forms/Add/ElementWithName/AddProductsHelper";


export default function AllProduit(){
    const [modalOpenCategorie,setmodalOpenCategorie]=useState(false);
    const [modalOpenMarque,setmodalOpenMarque]=useState(false);
    let history = useHistory();

    const redirectAddProduct = () => {
        history.push('add_product')
      }

    const redirectCategorie = () => {
        setmodalOpenCategorie(true);
      }
    const redirectMarque = () => {
        setmodalOpenMarque(true);
      }
    const redirectAllCategorie = () => {
        history.push('all_categorie')
      }
    const redirectAllMarque = () => {
        history.push('all_marque')
      }

    return (
        <>
         <Container fluid>
          {(modalOpenCategorie===true)?
                <AddProductsHelper show={modalOpenCategorie}setShow={setmodalOpenCategorie} edit={""} type="categorie"/>:
                (modalOpenMarque===true)?<AddProductsHelper show={modalOpenMarque} setShow={setmodalOpenMarque} edit={""} type="marque"/>:null}
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header style={{paddingTop:"20px",paddingLeft:"50px"}}>
                            <Row>
                                <Button
                                    className="btn-wd btn-outline mr-1"
                                    type="button"
                                    variant="primary"
                                    onClick={redirectAddProduct}
                                >
                                    Ajouter un produit
                                </Button>
                                <Button
                                    className="btn-wd btn-outline mr-1"
                                    type="button"
                                    variant="primary"
                                    onClick={(redirectCategorie)}
                                >
                                    Ajouter une Categorie
                                </Button>
                                <Button
                                    className="btn-wd btn-outline mr-1"
                                    type="button"
                                    variant="primary"
                                    onClick={(redirectMarque)}
                                >
                                    Ajouter une Marque
                                </Button>
                            </Row>
                            <Row style={{marginTop:'30px'}}>
                                <Button
                                    className="btn-wd btn-outline mr-1"
                                    type="button"
                                    variant="info"
                                    onClick={(redirectAllCategorie)}
                                >
                                       <i className="fa fa-eye" />&nbsp;&nbsp;Toutes les Categories
                                </Button>
                                <Button
                                    className="btn-wd btn-outline mr-1"
                                    type="button"
                                    variant="info"
                                    onClick={(redirectAllMarque)}
                                >
                                     <i className="fa fa-eye" />&nbsp;Toutes les Marques
                                </Button>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <TableProduit/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    )
}
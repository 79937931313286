import React,{useEffect,useState} from "react";
import { Link, useLocation } from "react-router-dom";

// react-bootstrap components
import {
  Collapse,
  Nav,
} from "react-bootstrap";
import AuthService from "services/Api/Controllers/AuthService";
import SocieteServices from "services/Api/Controllers/SocieteServices";
import StatusUser from "lib/Constant/Status/User.constant";
import Select from "react-select";
import TypeOption from "lib/Constant/interface/forms";
import { Spinner } from "reactstrap";


function Sidebar({ routes, image, background }) {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the user collapse
  const [userCollapseState, setUserCollapseState] = useState(false);
  const [currentUser, setCurrentUser] =useState<string|number>("");
  const [currentStatus, setCurrentStatus] =useState<string|number>("");
  
  const [SocieteName, setSocieteName] = useState([]);
  const [societeSelected, setsocieteSelected] = useState<TypeOption>();
  const [loading, setLoading] = useState<Boolean>(true);
  const [changeChargeSociete, setChangeChargeSociete] = useState<Boolean>(false);
  
  // this is for the rest of the collapses
  const [state, setState] = useState({});
  useEffect(() => {
    let isSubscribed = true;

    setState(getCollapseStates(routes));
    const user=AuthService.getNameUser();
    setCurrentUser(user);

    const status=AuthService.getLevelAdmin();
    const stat=""+status
    setCurrentStatus(StatusUser.filter(item=>item.value===stat)[0].label)


    const societename=SocieteServices.getCurrentSocieteName();
    const societeid=SocieteServices.getSociete();
    setsocieteSelected({value:societeid,label:societename});

    async function loadData() {
      const societes = await SocieteServices.getMySocietes();
      if(loading) {
        if(isSubscribed)  {
        setSocieteName(societes);
        setLoading(false)
        }
      }
    }
    if(loading) loadData();
    return () => {isSubscribed=false; };

  }, []);
  function deleteStorage(){
    localStorage.removeItem('societe');
    localStorage.removeItem('usedsociete');
    // cookies.remove('societe');
    // cookies.remove('usedsociete');
  }
  function reload(){
    window.location.reload(false);
  }
  function saveToLocalStorage(id,nom){
    setTimeout(function() {
      try {
        //const cookies = new Cookies();
        //deleteStorage(cookies);
        deleteStorage();
        localStorage.setItem('usedsociete', id);
        localStorage.setItem('societe', nom);
        // cookies.set('usedsociete', id);
        // cookies.set('societe', nom);
      } catch(e) {
        console.log("error save to LocalStorage")
      }
      reload();
      setChangeChargeSociete(false);
    }, 1000);
  }
  // cela crée l'état initial de ce composant basé sur les routes de collapse
  // qu'il obtient via routes prop
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // ceci vérifie si l'un des volets doit être ouvert par défaut lors d'un nouveau rendu de ce composant.
  // par exemple, lors de l'actualisation de la page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };
  // cette fonction crée les liens et les rabats qui apparaissent dans la barre latérale (menu de gauche)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.state==="AdminConnected") {
        if(AuthService.getLevelAdmin()!==0)
        return null;
      }
      if (prop.state==="accesProduct") {
        if(AuthService.getAccesProduct()!==true)
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <Nav.Item
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            as="li"
            key={key}
          >
            <Nav.Link
              className={state[prop.state] ? "collapsed" : ""}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
              aria-expanded={state[prop.state]}
            >
              <i className={prop.icon}></i>
              <p>
                {prop.name} <b className="caret"></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul">{createLinks(prop.views)}</Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }
      return (
        <Nav.Item
          className={activeRoute(prop.layout + prop.path)}
          key={key}
          as="li"
        >
          <Nav.Link to={prop.layout + prop.path} as={Link}>
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                {/* <span className="sidebar-mini">{prop.mini}</span> */}
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  return (
    <>
      <div className="sidebar" data-color={background} data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo">
            {/* <a className="simple-text logo-mini" >
              <div className="logo-img">
                <img src={require("assets/img/logo.svg").default} />
              </div>
            </a> */}
            <a className=" logo-normal"  style={{color:"black"}}>
            {(changeChargeSociete)?<Spinner animation="border" variant="primary" />:
              <Select
                          style={{color:"black"}}
                          name="societeSelected"
                          value={societeSelected}
                          onChange={(value) => {
                            setChangeChargeSociete(true);
                            saveToLocalStorage(value.value,value.label);
                            setsocieteSelected(value)
                            }
                          }
                          options={SocieteName.map((item) => new Object({ value: item._id, label: item.nom}))}
                          placeholder="Societé"
              />
            }
            </a>
          </div>
          <div className="user">
            <div className="photo">
              <img src={require("assets/img/default-avatar.png").default} ></img>
            </div>
            <div className="info">
            <a
                className={userCollapseState ? "collapsed" : ""}
                data-toggle="collapse"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setUserCollapseState(!userCollapseState);
                }}
                aria-expanded={userCollapseState}
              >
                <span>{currentUser}</span>
                <span style={{fontSize:"x-small",fontStyle:"italic"}}>{currentStatus}</span>
                </a>
                <Collapse  in={userCollapseState}>
                <div>
                  <Nav as="ul">
                    <li>
                      <a
                        className="profile-dropdown"
                        href=""
                        onClick={(e) => { AuthService.logOut();e.preventDefault();window.location.reload(false)}}
                      >
                        <span className="sidebar-mini"><i className="fas fa-sign-out-alt"></i></span>
                        <span className="sidebar-normal">Se Déconnecter</span>
                      </a>
                    </li>
           
                  </Nav>
                </div>
              </Collapse>
            </div>
          </div>
          <Nav as="ul">{createLinks(routes)}</Nav>
        </div>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: "url('" + image + "')",
          }}
        ></div>
      </div>
    </>
  );
}

export default Sidebar;

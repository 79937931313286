/* eslint-disable no-unused-vars */
import React from "react";
import {useEffect,useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactWizard from "react-bootstrap-wizard";
import {
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { Redirect } from "react-router-dom";



import Step1 from "./Wizard/Step1";
import Step2 from "./Wizard/Step2";
import Step3 from "./Wizard/Step3";
import Step4 from "./Wizard/Step4";
import Step5 from "./Wizard/Step5";
import OffreService from "services/Api/Controllers/OffreService";
import AuthService from "services/Api/Controllers/AuthService"
import StatusPropal from "lib/Constant/Status/Propal.constant";
import MailService from "services/Api/Controllers/MailService";
import EmailSend from "components/Email/SendMail";
import { useHistory } from "react-router-dom";
import Step7 from "./Wizard/Step7";
import SocieteServices from "services/Api/Controllers/SocieteServices";
export default function SaSp({match}) {
  let history = useHistory();
const steps = [
  { stepName: "Prospect", component: Step1,stepProps: {match: match}},
  { stepName: "SA/SP", component: Step4,stepProps: {match: match} },
  { stepName: "Tarification", component: Step7,stepProps: {match: match}},
];

  const [alertState, setAlertState] = useState(null);
  const [isEnd, setIsEnd] = useState("");
  const [redirectEnd, setredirectEnd] = useState(false)
  const [isMail,setIsmail]=useState(null);
  
  const onSubmitpropal = (item) => {
    var data_current = new Object({
      type:"sasp",
      createdby:AuthService.getIDUser(),
      prospect:item['Prospect']['id'],
      listTarifaire:item['Tarification']['inputAllSelected'],
      sasp:item['SA/SP']['listsasp'],
      status:StatusPropal[0].value,
      societeCurrent:SocieteServices.getSociete(),
    })
    async function data() {
      const boolres = await OffreService.savePsasptoDB(data_current)
      setIsEnd(boolres);
    }
    data();
  }
  useEffect(()=>{
    if(isEnd!==""&&isEnd!=="error"&&isEnd!=="existdeja"){
      console.log(isEnd)
      setAlertState(  
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="L'ajout a été effectué avec succès"
          onConfirm={() => hideAlertAttente()}
          onCancel={() => hideAlertAttente()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Suivant"
        >
        </SweetAlert>
        )
      }
      if(isEnd==="existdeja" || isEnd==="error"){
        setAlertState(  
          <SweetAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Une erreur c'est produite! Veuillez réessayer plus tard."
            onConfirm={() => hideAlertexist()}
            onCancel={() => hideAlertexist()}
            confirmBtnBsStyle="primary"
            confirmBtnText="Fermer"
          >
          </SweetAlert>
          )
        }
  },[isEnd,isMail])


  const hideAlertViewPdf = () => {
    setAlertState(  
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title=""
        onConfirm={() => hideAlertMailSend()}
        onCancel={() => hideAlertMailSend()}
        confirmBtnBsStyle="primary"
        confirmBtnText="Voir le pdf"
      >
      </SweetAlert>
      )
  };
  const MailSend=()=>{
    setAlertState(null);
    async function loadEditData() {
        const result= await MailService.GetnewPropalMailer(isEnd);
        setIsmail(result);
    }  
    loadEditData();
  }

   const hideAlertMailSend=()=>{
    window.open(`${process.env.REACT_APP_API_BASE_URL}pdfPropal/${isEnd}.pdf`, "_blank")
    setAlertState(
      <SweetAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Voulez-vous envoyer la proposition au client ?"
      onConfirm={() => MailSend()}
      onCancel={() => hideAlertEnd()}
      confirmBtnBsStyle="primary"
      cancelBtnBsStyle="danger"
      confirmBtnText="Oui"
      cancelBtnText="Non"
      showCancel
    >
    </SweetAlert>
    );
  }
  const hideAlertEnd=()=>{
    setAlertState(null);
    setredirectEnd(true);
  }
  const hideAlertAttente = () => {
    setAlertState( 
    <SweetAlert
          info
          style={{ display: "block", marginTop: "-100px" }}
          title="Merci de patientez,"
          onConfirm={() => hideAlertViewPdf()}
          showConfirm={false}
        >
           Le PDF sera disponible dans quelques instant.<br/><br/>
          <Spinner animation="border" variant="primary" />
        </SweetAlert>
    )
      setTimeout(() => {
        hideAlertViewPdf();
      }, 5000);
  };

  const hideAlertexist = () => {
    setAlertState(null);
  };
  if(redirectEnd){
    return (<Redirect to="/admin/prospect" />)
  }
  if(isMail!==null) return <EmailSend data ={isMail} fromSource="create"/>
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
              <ReactWizard
                steps={steps}
                navSteps
                title="Creation d'une Propal"
                description="Merci de compléter les éléments suivants "
                headerTextCenter
                validate
                color="primary"
                previousButtonText="Retour"
                finishButtonText="Terminer"
                nextButtonText="Suivant"
                finishButtonClasses="btn-primary btn-wd"
                nextButtonClasses="btn-primary btn-wd"
                previousButtonClasses="btn-wd"
                finishButtonClick={(item) => {
                  onSubmitpropal(item);
                }}
              />
          </Col>
        </Row>
      </Container>
      {alertState}
    </>
  );
}
import React,{useState} from "react";

// react-bootstrap components
import {
  Button,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
} from "react-bootstrap";

function AdminNavbar() {
  const [collapseOpen, setCollapseOpen] = useState(false);
  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                  className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                  variant="dark"
                  onClick={() => document.body.classList.toggle("sidebar-mini")}
                >
                  <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                  <i className="fas fa-bars visible-on-sidebar-mini"></i>
                </Button>
                <Button
                  className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                  variant="dark"
                  onClick={() =>document.documentElement.classList.toggle("nav-open") }
                >
                  <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                  <i className="fas fa-bars visible-on-sidebar-mini"></i>
                </Button>
            </div>
          </div>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <button
                    className="navbar-toggler navbar-toggler-right border-0"
                    type="button"
                    onClick={() => setCollapseOpen(!collapseOpen)}
                >
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
              </button>
            </div>
          </div>
         
         


          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav className="nav mr-right" navbar>
              <Form
                className="navbar-form navbar-left navbar-search-form ml-3 ml-lg-0"
                role="search"
              >
                <InputGroup>
                  <i className="nc-icon nc-zoom-split"></i>
                  <Form.Control
                    defaultValue=""
                    placeholder="Recherche..."
                    type="text"
                  ></Form.Control>
                </InputGroup>
              </Form>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;

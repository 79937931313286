
// @ts-ignore
import LoginPage from "view/Auth/LoginPage";
// @ts-ignore
import SelectSocietePage from "view/Auth/SocietePage.tsx";
import Error500 from "view/error/error500";

const routes_auth=[
    {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "nc-icon nc-puzzle-10",
    views: [
      {
        path: "/login",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginPage,
      },
      {
        path: "/select-societe",
        layout: "/auth",
        name: "Societe",
        mini: "RP",
        component: SelectSocietePage,
      },
      {
        path: "/error",
        layout: "/auth",
        name: "Error",
        mini: "RP",
        component: Error500,
      },
    ],
  }];
  export default routes_auth;
import { isNil, isUndefined } from "lodash-es";
import Cookies from 'universal-cookie';
import ApiService from "../ApiService";
import AuthService from "./AuthService";
import { decodeJwt } from "../../Global/JwtDecode";

class SocieteServices {

    static selectedSociety() {
        if(SocieteServices.getSociete()===''){
            return false;
        }
        return true;
    }

    static getSociete() {
        let societe = localStorage.getItem('usedsociete');
        if(isNil(societe) || isUndefined(societe) || societe === '') {
          const cookies = new Cookies();
          societe = cookies.get('usedsociete');
          if(isNil(societe) || isUndefined(societe) || societe === '') {
            return '';
          }
        }
        return societe;
      }
      static getCurrentSocieteName() {
        let societe = localStorage.getItem('societe');
        if(isNil(societe) || isUndefined(societe) || societe === '') {
          const cookies = new Cookies();
          societe = cookies.get('societe');
          if(isNil(societe) || isUndefined(societe) || societe === '') {
            return '';
          }
        }
        return societe;
      }

    static getSocieteFromAuth(){
        const token=AuthService.getToken();
        const decodeToken=decodeJwt(token);
        const current_societe=decodeToken.user.societes;
        return current_societe;
    }
    static getAdminLevelFromAuth(){
        const token=AuthService.getToken();
        const decodeToken=decodeJwt(token);
        const current_societe=decodeToken.user.levelAdmin;
        return current_societe;
    }

     static getMySocietes(){
      return  ApiService.post("societe/getSociete",{_id_societe:this.getSocieteFromAuth()},AuthService.getToken())
      .then(res=>{
        return res;
      })
      .catch(error=>{
        throw error;
      })
    }
     static getSocietesById(id){
      return  ApiService.post("societe/getSociete",{_id_societe:id},AuthService.getToken())
      .then(res=>{
        return res;
      })
      .catch(error=>{
        throw error;
      })
    }
     static getALLSocietes(){
      return  ApiService.get("societe/getallSocieteID","")
      .then(res=>{
        return res;
      })
      .catch(error=>{
        throw error;
      })
    }
    static getByID(id){
      return ApiService.post("societe/getByIDGuest",{id:id})
      .then(res=>{
        console.log(res)
        return res;
      })
      .catch(error=>{
        throw error;
      })
    }
}
export default SocieteServices;
import React,{useState,useEffect } from "react";
import { Row, Col, FormGroup, FormControl, FormLabel, Spinner } from "react-bootstrap";
import ProspectService from "services/Api/Controllers/ProspectService";
import SweetAlert from "react-bootstrap-sweetalert";
import { Redirect } from "react-router-dom";
import OffreService from "services/Api/Controllers/OffreService";
import { handleAddressAutoComplete } from "components/MapsAutocomplete/AdressGoogleGetValue";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import BDCService from "services/Api/Controllers/BdcService";


// eslint-disable-next-line react/display-name
const Step1 = React.forwardRef((props, ref) => {
  //@ts-ignore.
  const CurrentIdPropal=(props?.match!==null || props?.match!==undefined)?props?.match?.params?.id:undefined;
  const CurrentIdBDC=(props?.match!==null || props?.match!==undefined)?props?.match?.params?.offre:undefined;
  const [CurrentIdProspect,setCurrentIdProspect]=useState(null);
  const [contact, setContact] = useState("");
  const [societe, setSociete] = useState("");
  const [phone, setPhone] = useState("");
  const [portable, setPortable] = useState("");
  const [email, setEmail] = useState("");
  const [adresse, setAdresse] = useState(null);
  const [loadingEdit,setloadingEdit ] = useState(true);
  const [loadingEditPropal,setloadingEditPropal] = useState(true);
  const [alertState, setAlertState] = useState(null);
  const [toRedirect, settoRedirect] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [allLocation,setAllLocation]=useState("");
  const [siret, setSiret] = useState("Non fournis");

  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => { setAlertState(null) }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      >
      </SweetAlert>
    )
  }
  const isValidate=()=>{
   
      if(contact!==""
      &&societe!==""
      &&phone!==""
      &&portable!==""
      &&email!==""
      &&adresse!==null)
      {
        return true; 
      }else{
        alertEvent("Vous devez indiquer toutes les informations !")
      }
      return false;
      
  }
  const saveData=()=>{
    const data={id:CurrentIdProspect,contact:contact,societe:societe,phone:phone,portable:portable,email:email,adresse:adresse,allLocation:allLocation,siret:siret}
    return data;
  }
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidate() 
    }, state: saveData(),
  }));

  useEffect(() => {
    let isSubscribed = true;
    async function loadEditData() {
      if (loadingEdit) {
        setloadingEdit(false)
        //@ts-ignore.
        const prospectEditVal = await ProspectService.getByID(CurrentIdProspect);
        if(isSubscribed)  {
          setContact(`${prospectEditVal.nom} ${prospectEditVal.prenom}`);
          setSociete(prospectEditVal.societe);
          setEmail(prospectEditVal.email);
          setPhone(prospectEditVal.tel);
          setPortable(prospectEditVal.tel);
          setAdresse(prospectEditVal.localisation);
        }
      }
      
    }
    async function loadDataPropal() {
        const prospectEditVal = await OffreService.getByID(CurrentIdPropal);
        if(isSubscribed&&loadingEditPropal)  {
          setCurrentIdProspect(prospectEditVal.prospect)
          setloadingEditPropal(false)
      }
    }
    async function loadDataCurrent() {
        const prospectEditVal = await BDCService.getByID(CurrentIdBDC);
        if(isSubscribed&&loadingEditPropal)  {
          setContact(prospectEditVal?.facturation?.contact);
          setSociete(prospectEditVal?.facturation?.societe);
          setPhone(prospectEditVal?.facturation?.phone);
          setPortable(prospectEditVal?.facturation?.portable);
          setEmail(prospectEditVal?.facturation?.email);
          setAllLocation(prospectEditVal?.facturation?.allLocation);
          setAdresse(prospectEditVal?.facturation?.adresse);
          setSiret(prospectEditVal?.facturation?.siret);
          setCurrentIdProspect(prospectEditVal?.prospect)
          setloadingEditPropal(false);
          setloadingEdit(false);
      }
    }

    if(CurrentIdBDC===undefined){
      if(loadingEditPropal) loadDataPropal()
      if (!loadingEditPropal&&loadingEdit&&CurrentIdProspect!==undefined) {
          loadEditData().catch(()=>{
            setAlertState(
              <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Vous n'avez pas accès à cette section!"
                onConfirm={() =>{ settoRedirect(true);setAlertState(null)}}
                onCancel={() =>{settoRedirect(true);setAlertState(null)}}
                confirmBtnBsStyle="info"
              >
              </SweetAlert>
            );
          });
      }
    }else{
        if(CurrentIdBDC!==undefined &&loadingEditPropal){
          loadDataCurrent().catch(()=>{
            setAlertState(
              <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Vous n'avez pas accès à cette section!"
                onConfirm={() =>{ settoRedirect(true);setAlertState(null)}}
                onCancel={() =>{settoRedirect(true);setAlertState(null)}}
                confirmBtnBsStyle="info"
              >
              </SweetAlert>
            );
          });
        }
      
    }

    return () => {isSubscribed=false; };
  }, [CurrentIdProspect,loadingEditPropal])

  useEffect(() => {
    (adresse===null||adresse===undefined)? null:handleAddressAutoComplete(adresse).then(data=> setAllLocation(data))
    return () => {
      setAllLocation("")
    }
  }, [adresse])
  
  if(toRedirect) return (<Redirect to="/admin/bdc" />)
  
  return (
    <>
    {alertState}
    <div className="wizard-step" ref={ref}>
      <p className="text-center">Informations Prospect</p>
      {(loadingEdit)?<Spinner animation="border" variant="primary" />:
        <Row >
          <Col md={{ span: 5, offset: 1 }}>
            <FormGroup>
              <FormLabel>Contact</FormLabel>
              <FormControl 
                onChange={(e)=>setContact(e.target.value)}
                type="text" 
                name="contact" 
                value={contact}
              />
               {contact !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
          <Col md={{ span: 5}}>
            <FormGroup>
              <FormLabel>
              N° de Siret
              </FormLabel>
              <FormControl
                type="text"
                name="siret"
                value={siret}
                onChange={(e)=>setSiret(e.target.value)}
              />
            </FormGroup>
          </Col> 
          <Col md={{ span: 5, offset: 1 }}>
            <FormGroup>
              <FormLabel>Societe</FormLabel>
              <FormControl 
                onChange={(e)=>setSociete(e.target.value)}
                type="text" 
                name="societe" 
                value={societe}
              />
               {societe !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
          <Col md={5}>
          <FormGroup>
              <FormLabel>Telephone</FormLabel>
              <FormControl 
                onChange={(e)=>setPhone(e.target.value)}
                type="text" 
                name="Telephone" 
                value={phone}
              />
               {phone !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
          <Col md={{ span: 5, offset: 1 }}>
            <FormGroup>
              <FormLabel>
                Email
              </FormLabel>
              <FormControl
                type="email"
                name="email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
              />
               {email !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
          <Col md={5}>
          <FormGroup>
              <FormLabel>Portable</FormLabel>
              <FormControl 
                onChange={(e)=>setPortable(e.target.value)}
                type="text" 
                name="Portable" 
                value={portable}
              />
               {portable !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
          <Col md={{ span: 10, offset: 1 }}>
            <FormGroup>
              <FormLabel>Adresse</FormLabel>
              <GooglePlacesAutocomplete           
                  autocompletionRequest = {{componentRestrictions:{ country:"fr" }}}
                  apiOptions={{ language: 'fr', region: 'fr' }}
                  selectProps={{
                      placeholder: "...." ,
                      value:adresse,
                      onChange: setAdresse
                  }}
                />
               {adresse !== null ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
        </Row>
      }
    </div>
    </>
  );
});

export default Step1;

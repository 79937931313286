/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from "react";
// react-bootstrap components
import {
  Container,
  Spinner,
  Navbar,
  Row,
  Col,
  Card,
  Button,
  Badge,
} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import OffreService from "services/Api/Controllers/OffreService";
import ProspectService from "services/Api/Controllers/ProspectService";
import SocieteServices from "services/Api/Controllers/SocieteServices";
import { getClientIp } from "services/Global/GetIp";
import Error500 from "view/error/error500";
import { datebyValue } from 'lib/outilsForms'
import ViewPdf from "components/Pdf/ViewPdf";


function GuestAcces({ match }) {

  const [IsLoading, setIsLoading] = useState(true)
  const [IsRedirect, setIsRedirect] = useState(false)
  const [DataProspect, setDataProspect] = useState();
  const [Datasociete, setDatasociete] = useState();
  const [dataPropal, setdataPropal] = useState();
  const [loadingDataPropal, setloadingDataPropal] = useState<Boolean>(true);
  const [loadingDataProspect, setloadingDataProspect] = useState<Boolean>(true);
  const [loadingDatasociete, setloadingDatasociete] = useState<Boolean>(true);
  const [AlertState, setAlertState] = useState(null);
  const [IPClient, setIPClient] = useState("");
  const [response, setresponse] = useState("");
  console.log(match?.params)
  useEffect(() => {
    let isSubscribed = true;
    async function loadDataPropal() {
      try {
         const propal = await OffreService.getByIDOffreGuest(match.params.id);
       if (isSubscribed) {
          setdataPropal(propal);
          setloadingDataPropal(false);
        }
      } catch (error) { setIsRedirect(true) }
    }
    async function loadDataProspect() {
      try {
        //@ts-ignore.
        const prospect = await ProspectService.getByIDGuest((dataPropal === undefined) ? "" : dataPropal.prospect);
        if (isSubscribed) {
          setDataProspect(prospect)
          setloadingDataProspect(false);
        }
      } catch (error) { setIsRedirect(true) }
    }
    async function loadDataSociete() {
      try {
        //@ts-ignore.
        const societe = await SocieteServices.getByID((DataProspect === undefined) ? "" : DataProspect.societeAssocie);
        if (isSubscribed) {
          setDatasociete(societe)
          setloadingDatasociete(false);
        }
      } catch (error) { console.log(error); setIsRedirect(true) }
    }
    if (loadingDataPropal) {
      loadDataPropal()
    }

    if (loadingDataProspect && !loadingDataPropal) {
      loadDataProspect()
    }
    if (loadingDatasociete && !loadingDataProspect && !loadingDataPropal) {
      loadDataSociete()
    }

    if (!loadingDataPropal && !loadingDataProspect && !loadingDatasociete) {
      getClientIp().then((data: any) => setIPClient(data));
      document.body.style.backgroundColor = "#f9fafb";
      setIsLoading(false);
    }
    return () => { isSubscribed = false }

  }, [loadingDataPropal, loadingDataProspect, loadingDatasociete])
  useEffect(() => {
    if (response === "ok") {
      setAlertState(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={"Votre demande a été enregistrée"}
          onConfirm={() => window.location.reload(false)}
          onCancel={() => window.location.reload(false)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Fermer"
        >
        </SweetAlert>
      )
    }
    if (response === "error") {
      setAlertState(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Une erreur s'est produite ! Veuillez réessayer plus tard ..."
          onConfirm={() => setAlertState(null)}
          onCancel={() => setAlertState(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Fermer"
        >
        </SweetAlert>
      )
    }
  }, [response])

  const alertEvent = (info, title) => {
    setAlertState(
      <SweetAlert
        input
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={(e) => {
          setAlertState(null);
          UpdateisValidated(info, e)
        }
        }
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText={(info === "refus") ? "Oui, refuser !" : "Oui, accepter !"}
        cancelBtnText="non"
        validationMsg="Veuillez saisir votre nom"
        showCancel
      >
        Une fois confirmé, vous ne pourrez plus changer votre choix !
        <br></br><br></br>
        Veuillez indiquer votre nom comme preuve de signature :
        <br></br><br></br>
      </SweetAlert>
    )
  }
  const ButtonAction = () => {
    return (
      <>
        <div style={{textAlign:'center'}}>
          <Button
            variant="warning"
            onClick={() => alertEvent("refus", "Vous êtes sur le point de refuser la proposition")}
          >
            Refuser
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            variant="success"
            onClick={() => alertEvent("accepter", "Vous êtes sur le point d'accepter la proposition")}
          >
            Accepter
          </Button>
        </div>
      </>
    )
  }
  const ViewSignature = () => {
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <p>Le {datebyValue(dataPropal?.isaccepted?.date)}, {dataPropal?.isaccepted?.signature}</p>
          <p>a {(dataPropal?.isaccepted?.accepted === 0) ? "refusé" : "accepté"} cette proposition. </p>
          <p>Avec l&apos;adresse IP: <Badge variant="primary">{dataPropal?.isaccepted?.ip}</Badge></p>
        </div>

      </>
    )
  }
  const UpdateisValidated = (info, signature) => {
    const accepted = (info === "refus") ? 0 : 1;

    const datas = { accepted: accepted, signature: signature, ip: IPClient, date: Date.now() }
    async function data() {
      const boolrest = await OffreService.Guestupdateisaccepted(match.params.id, datas)
      setresponse(boolrest);
    }
    data();
  }

  if (IsRedirect) return <Error500 />
  if (IsLoading) {
    return <Spinner animation="border" variant="primary" />
  } else {
    return (
      <>
        {AlertState}
        <Navbar className="w-100" expand="lg" style={{ background: "#f9fafb" }}>
          <Container>
            <div className="navbar-wrapper">
              <Navbar.Brand>
                <div className="logo">
                  <div className="logo-img">
                    <img
                      //@ts-ignore.
                      src={require(`assets/img/${Datasociete.logo}`).default}
                      alt="logo"
                      width="200px"
                    />
                  </div>
                </div>
              </Navbar.Brand>
            </div>
          </Container>
        </Navbar>
        <Container >
          <Container >
            <Row style={{ paddingTop: '20px' }} >
              <Col md="8">
                <Card className="card-user" style={{ background: "transparent", border: 'none'}}>
                  <Card.Header style={{background: "transparent"}}>
                  </Card.Header>
                  <Card.Body style={{ minHeight: '130px' }}>
                    <Card.Title style={{ textAlign: 'center' }}>
                        <ViewPdf url={`${process.env.REACT_APP_API_BASE_URL}pdfPropal/${match.params.id}.pdf`}/>
                    </Card.Title>
                  </Card.Body>
                  <Card.Footer style={{ textAlign: 'center' }}>
                  </Card.Footer>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-user" style={{ background: "transparent", border: "none" }}>
                  <Card.Header style={{marginTop:'20px',marginBottom: '90px', background: 'transparent', textAlign: 'center' }}>
                    <Row>
                      <Col md="12">
                        <Button 
                          className="btn-block"
                          onClick={() => window.open(`${process.env.REACT_APP_API_BASE_URL}pdfPropal/${match.params.id}.pdf`, "_blank")}
                        >
                          Telecharger
                        </Button>
                      </Col>
                    </Row>
                    {(dataPropal.isaccepted === undefined) ? <ButtonAction /> : null}
                  </Card.Header>
                  <Card.Body style={{ minHeight: '130px' }}>
                    <div className="author" style={{ borderBottom: "5px solid #EEEEEE" }}>
                      <Card.Title as="h2" style={{ fontWeight: "bold",fontSize:'large'}}> <br></br>{DataProspect.nom} {DataProspect.prenom} 
                      </Card.Title>
                      <p className="card-description">{/*@ts-ignore*/DataProspect.societe}</p>
                    </div> 
                    <br></br>
                    <br></br>
                    <p className="card-description" style={{textAlign:'center'}}>{/*@ts-ignore*/DataProspect.allLocation.address} {/*@ts-ignore*/DataProspect.allLocation.codepostal}  {/*@ts-ignore*/DataProspect.allLocation.city}
                    </p>
                  </Card.Body>
                </Card>
                {(dataPropal.isaccepted === undefined) ? null : <ViewSignature />}
              </Col>
            </Row>
            <footer className="footer " style={{ background: "white", padding: "0" }} >
              <p className="copyright text-center" style={{ color: "black" }}>
                © {new Date().getFullYear()}{" "}
                <a >{/*@ts-ignore*/Datasociete.nom}</a>
              </p>
            </footer>
          </Container>
        </Container>
      </>
    );
  }
}

export default GuestAcces;

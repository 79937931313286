import TagsInput from "components/TagsInput/TagsInput";
import { isNumber, maxLength } from "lib/outilsForms";
import React, { useEffect, useState } from "react";
import { Row, Col, Button, InputGroup, FormGroup, Form, Alert } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { CustomInput } from "reactstrap";
import Select from "react-select";
import PayementStatus from "lib/Constant/Status/Payement.constant";
import TypeOption from "lib/Constant/interface/forms";
import OffreService from "services/Api/Controllers/OffreService";

// eslint-disable-next-line react/display-name
const Step5 = React.forwardRef((props, ref) => {

  const [sainputList, setsainputList] = useState([{ titre: "", isarticle: false, description: "", tag: [] }]);
  const [spinputList, setspinputList] = useState([{ titre: "", isarticle: false, description: "", tag: [] }]);
  const [typePayement, settypePayement] = React.useState<TypeOption|undefined>(undefined);

  const [priceSa, setpriceSa] = useState("")
  const [priceSp, setpriceSp] = useState("")
  const [ifsa, setifsa] = useState(true);
  const [alertState, setAlertState] = useState(null);
  const [loadingEdit, setloadingEdit] = useState(true)

  const handleInputDescriptionChangeSa = (e, index) => {
    const { name, value } = e.target;
    const list = [...sainputList];
    if (maxLength(value, 37) || sainputList) {
      list[index][name] = value;
      setsainputList(list);
    } else {
      setAlertState(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-37px" }}
          title="Vous ne pouvez pas dépasser 37 caractères "
          onConfirm={() => { setAlertState(null) }}
          onCancel={() => setAlertState(null)}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          confirmBtnText="J'ai compris!"
        >
        </SweetAlert>
      )
    }
  };
  // gérer l'événement de clic du bouton Supprimer
  const handleRemoveDescriptionClickSa = index => {
    const list = [...sainputList];
    list.splice(index, 1);
    setsainputList(list);
  };
  // gérer l'événement de clic du bouton Ajouter
  const handleAddDescriptionClickSa = () => {
    if(sainputList.length<6){
      setsainputList([...sainputList, { titre: "", isarticle: false, description: "", tag: [] }]);
    }else{
      alertEvent("Vous ne pouvez pas ajouter plus de 6 elements");
    }
  };
  const handleInputDescriptionChangeSp = (e, index) => {
    const { name, value } = e.target;
    const list = [...spinputList];
    if (maxLength(value, 37) || value==="") {
      list[index][name] = value;
      setspinputList(list);
    } else {
      setAlertState(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-37px" }}
          title="Vous ne pouvez pas dépasser 37 caractères "
          onConfirm={() => { setAlertState(null) }}
          onCancel={() => setAlertState(null)}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          confirmBtnText="J'ai compris!"
        >
        </SweetAlert>
      )
    }
  };
  // gérer l'événement de clic du bouton Supprimer
  const handleRemoveDescriptionClickSp = index => {
    const list = [...spinputList];
    list.splice(index, 1);
    setspinputList(list);
  };
  // gérer l'événement de clic du bouton Ajouter
  const handleAddDescriptionClickSp = () => {
    if(spinputList.length<6){
      setspinputList([...spinputList, { titre: "", isarticle: false, description: "", tag: [] }]);
    }else{
      alertEvent("Vous ne pouvez pas ajouter plus de 6 elements");
    }
  };
  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => { setAlertState(null) }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      >
      </SweetAlert>
    )
  }
  const isValidated = () => {
    let sainputListValide = (sainputList.length === 1) ? sainputList[0].titre !== "" : true;
    const spinputListValide = (spinputList.length === 1) ? spinputList[0].titre !== "" : true;
    const saprice = (ifsa) ? (priceSa===null||priceSa==="")?false:true:true;
    const spprice = (priceSp===null||priceSp==="")?false:true;
    const payementIs = (typePayement===undefined)?false:true;
    let IsValidatesa=true,IsValidatesp=true;
    if(ifsa){
      sainputList.forEach(item=>(
        // eslint-disable-next-line no-unused-vars
        (item.titre!=="")?null:IsValidatesa=false
      ))
    }
    spinputList.forEach(item=>(
      // eslint-disable-next-line no-unused-vars
      (item.titre!=="")?null:IsValidatesp=false
    ))

    if(!ifsa){ sainputListValide=true; }
    if(!IsValidatesa) alertEvent("Vous avez sélectionné un sujet dans la solution actuelle que vous n'avez pas completé")
    if(!IsValidatesp) alertEvent("Vous avez sélectionné un sujet dans la solution proposée que vous n'avez pas completé")
    if(!saprice) {setpriceSa(""); alertEvent("Vous devez entrer un prix dans la solution actuelle")}
    if(!spprice) {setpriceSp("");alertEvent("Vous devez entrer un prix dans la solution proposée")}
    if(!payementIs)alertEvent("Vous devez entrer le periode de payement")

    const isvalid=sainputListValide&&spinputListValide&&saprice&&spprice&&IsValidatesa&&IsValidatesp&&payementIs;
    return isvalid;
  };

  useEffect(() => {
    let isSubscribed = true;
    async function loadEditData() {
      if (loadingEdit) {
        setloadingEdit(false)
        //@ts-ignore.
        
        const propalVal = await OffreService.getByID(props?.match?.params?.propal);
        if(isSubscribed)  {
          setsainputList(propalVal?.sasp[0].sa)
          setspinputList(propalVal?.sasp[0].sp)
          settypePayement(propalVal?.sasp[0].typepayement)        
          setpriceSa(propalVal?.sasp[0].pricesa)        
          setpriceSp(propalVal?.sasp[0].pricesp)        
          setifsa(propalVal?.sasp[0].saexist)     
        }
      }
    }
    if (loadingEdit) {
      if(props?.match?.params?.propal!==undefined){
        loadEditData()
      }else{
        setloadingEdit(false)
      }
    }
    return () => {isSubscribed=false; };
  }, [])


  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state: {
      listsasp: {
        saexist:ifsa,
        sa:sainputList,
        pricesa:priceSa,
        sp:spinputList,
        pricesp:priceSp,
        typepayement:typePayement
      }
    }
  }));


  return (
    <>
      {alertState}
      <div className="wizard-step">
        <Row style={{padding: "30px"}}>
          <Col md="12" style={{padding:"23px",boxShadow:"rgb(0 0 0) 0px 0px 9px -4px, rgb(0 0 0) -2px 2px 21px -10px"}}>
            <Row>
              <Col md={{ offset: 4, span: 4 }}>
                <p  style={{ fontSize: "x-large" }}>
                  <b>Solution actuelle</b>
                </p>
              </Col>
              <Col md={{ span: 3 }} style={{ marginTop: "5px" }}>
                <CustomInput
                  defaultChecked={ifsa}
                  type="switch"
                  id="customSwitch"
                  onClick={() => setifsa(!ifsa)}
                />
              </Col>
            </Row>
            {(ifsa) ?
              <FormGroup>
                {/* Description */}
                <Row>
                  <Col md={{ span: 3, offset: 8 }} style={{ marginTop: "15px" }}>
                    <InputGroup className="mb-2" >
                      <InputGroup.Prepend>
                        <InputGroup.Text>€</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        placeholder="Prix actuel"
                        value={priceSa}
                        onChange={(e) => {
                          //@ts-ignore.
                          const value = e.target.value;
                          if (isNumber(value) || value === "") {
                            setpriceSa(value)
                          } else {
                            alertEvent("Vous devez entrer un prix")
                          }
                        }}
                      />
                    </InputGroup>

                    {priceSa !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
                  </Col>
                  <Col md="12">
                    {sainputList.map((x, i) => {
                      return (
                        <Row key={i} style={{ marginBottom: "15px" }}>
                          <Col md="12">
                            <Row>
                              <Col md={{ span: 8, offset: 1 }} style={{ marginBottom: "15px", paddingLeft: "0px" }}>
                                <Form.Check >
                                  <Form.Check.Label>
                                    <Form.Check.Input
                                      //@ts-ignore.
                                      value={x.isarticle}
                                      type="checkbox"
                                      onChange={() => {
                                        const list = [...sainputList];
                                        list[i]['isarticle'] = !list[i]['isarticle'];
                                        setsainputList(list);
                                      }
                                      }
                                    ></Form.Check.Input>
                                    <span className="form-check-sign"></span>
                                    <span style={{ fontSize: "large", textTransform: "none", color: "black" }}>
                                      Références d&apos;articles ?
                                              </span>
                                  </Form.Check.Label>
                                </Form.Check>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={{ span: 4, offset: 1 }}>
                            <InputGroup className="mb-2">
                              <InputGroup.Prepend>
                                <InputGroup.Text>{37 - x.titre.length}</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                name="titre"
                                placeholder="Sujet"
                                value={x.titre}
                                onChange={e => handleInputDescriptionChangeSa(e, i)}
                              />
                            </InputGroup>
                            {x.titre!=="" ? null : (
                              <label className="error" style={{ color: "red", textTransform: "none" }}>
                                Ce champ ne peut pas etre vide.
                              </label>
                            )}
                          </Col>
                          {!(x.isarticle) ?
                            <Col md="4" >

                              <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>{37 - x.description.length}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  name="description"
                                  placeholder="Description"
                                  value={x.description}
                                  onChange={e => handleInputDescriptionChangeSa(e, i)}
                                />
                              </InputGroup>
                            </Col>
                            :
                            <Col md="4" style={{ fontSize: "large" }}>
                              <TagsInput
                                //@ts-ignore.
                                value={x.tag}
                                maxTags={3}
                                validationRegex={/^.{1,8}$/}
                                onChange={(value) => {
                                  const list = [...sainputList];
                                  list[i]['tag'] = value;
                                  setsainputList(list);
                                }}
                                tagProps={{
                                  className: "react-tagsinput-tag tag-fill tag-azure",
                                  placeholder: "test"
                                }}
                                inputProps={
                                  {
                                    className: 'react-tagsinput-input',
                                    placeholder: 'Reference'
                                  }
                                }
                              />
                            </Col>
                          }

                          {sainputList.length !== 1
                            &&
                            <Col md="1" >
                              <Button

                                variant="warning"
                                onClick={() => handleRemoveDescriptionClickSa(i)}
                              >
                                <span style={{ fontSize: "large" }}>-</span>
                              </Button>
                            </Col>
                          }

                          {sainputList.length - 1 === i
                            &&
                            <Col md="1" >
                              <Button

                                variant="info"
                                onClick={handleAddDescriptionClickSa}
                              >
                                <span style={{ fontSize: "large" }}>+</span>
                              </Button>
                            </Col>
                          }

                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </FormGroup>
              : (
                <Row>
                  <Col md={"12"}>
                    <div className="text-center" style={{ padding: "20px" }}>
                      <Alert variant="warning">
                        <span style={{ fontSize: "large", fontWeight: "bold" }}>
                          <i className="nc-icon nc-bell-55"></i> Aucune solution actuelle n&apos;est présente dans l&apos;entreprise
                </span>
                      </Alert>
                    </div>
                  </Col>
                </Row>
              )}
          </Col>
        </Row>
        <Row style={{padding: "30px"}}>
          <Col md="12"style={{padding:"23px",boxShadow:"rgb(0 0 0) 0px 0px 9px -4px, rgb(0 0 0) -2px 2px 21px -10px"}}>
            <Row>
              <Col md={{ offset: 4, span: 5 }}>
                <p  style={{ fontSize: "x-large" }}>
                  <b>Solution proposée</b>
                </p>
              </Col>
            </Row>
            <FormGroup>
              {/* Description */}
              <Row>
                <Col md={{ span: 3, offset: 8 }} style={{ marginTop: "15px" }}>
                  <InputGroup className="mb-2" >
                    <InputGroup.Prepend>
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Prix proposé"
                      value={priceSp}
                      onChange={(e) => {
                        //@ts-ignore.
                        const value = e.target.value;
                        if (isNumber(value) || value === "") {
                          setpriceSp(value)
                        } else {
                          alertEvent("Vous devez entrer un prix")
                        }
                      }}
                    />
                  </InputGroup>

                  {priceSp !== "" ? null : (
                    <label className="error" style={{ color: "red", textTransform: "none" }}>
                      Ce champ ne peut pas etre vide.
                    </label>
                  )}
                </Col>

                <Col md="12">
                  {spinputList.map((x, i) => {
                    return (
                      <Row key={i} style={{ marginBottom: "15px" }}>
                        <Col md="12">
                          <Row>
                            <Col md={{ span: 6, offset: 1 }} style={{ marginBottom: "15px", paddingLeft: "0px" }}>
                              <Form.Check >
                                <Form.Check.Label>
                                  <Form.Check.Input
                                    //@ts-ignore.
                                    value={x.isarticle}
                                    type="checkbox"
                                    onChange={() => {
                                      const list = [...spinputList];
                                      list[i]['isarticle'] = !list[i]['isarticle'];
                                      setspinputList(list);
                                    }
                                    }
                                  ></Form.Check.Input>
                                  <span className="form-check-sign"></span>
                                  <span style={{ fontSize: "large", textTransform: "none", color: "black" }}>
                                    Références d&apos;articles ?
                                              </span>
                                </Form.Check.Label>
                              </Form.Check>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={{ span: 4, offset: 1 }}>
                          <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                              <InputGroup.Text>{37 - x.titre.length}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              name="titre"
                              placeholder="Sujet"
                              value={x.titre}
                              onChange={e => handleInputDescriptionChangeSp(e, i)}
                            />
                          </InputGroup>
                          {x.titre!==""? null : (
                            <label className="error" style={{ color: "red", textTransform: "none" }}>
                              Ce champ ne peut pas etre vide.
                            </label>
                          )}
                        </Col>
                        {!(x.isarticle) ?
                          <Col md="4" >
                            <InputGroup className="mb-2">
                              <InputGroup.Prepend>
                                <InputGroup.Text>{37 - x.description.length}</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                name="description"
                                placeholder="Description"
                                value={x.description}
                                onChange={e => handleInputDescriptionChangeSp(e, i)}
                              />
                            </InputGroup>
                          </Col>
                          :
                          <Col md="4" style={{ fontSize: "large" }}>
                            <TagsInput
                              //@ts-ignore.
                              value={x.tag}
                              maxTags={3}
                              validationRegex={/^.{1,8}$/}
                              onChange={(value) => {
                                const list = [...spinputList];
                                list[i]['tag'] = value;
                                setspinputList(list);
                              }}
                              tagProps={{
                                className: "react-tagsinput-tag tag-fill tag-azure",
                                placeholder: "test"
                              }}
                              inputProps={
                                {
                                  className: 'react-tagsinput-input',
                                  placeholder: 'Reference'
                                }
                              }
                            />
                          </Col>
                        }

                        {spinputList.length !== 1
                          &&
                          <Col md="1" >
                            <Button

                              variant="warning"
                              onClick={() => handleRemoveDescriptionClickSp(i)}
                            >
                              <span style={{ fontSize: "large" }}>-</span>
                            </Button>
                          </Col>
                        }

                        {spinputList.length - 1 === i
                          &&
                          <Col md="1" >
                            <Button

                              variant="info"
                              onClick={handleAddDescriptionClickSp}
                            >
                              <span style={{ fontSize: "large" }}>+</span>
                            </Button>
                          </Col>
                        }
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{padding:'30px',paddingTop:"10px"}}>
          <Col md="12">
            <label style={{textTransform:"none"}}> 
              Periode de payement
            </label>
            <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="selectCategorie"
                value={typePayement}
                onChange={(value) => settypePayement(value)}
                options={PayementStatus}
                placeholder="Periode de payement"
             />
              {typePayement !== undefined ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
          </Col>
        </Row>
        
      </div>
    </>
  );


});

export default Step5;
